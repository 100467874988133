/* 

*/
[ui-view].ng-leave {
  display: none;
}
[ui-view].ng-leave.smooth {
  display: block;
}
.smooth.ng-animate {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fade-in-right-big.ng-enter {
  -webkit-animation: fadeInRightBig 0.5s;
  animation: fadeInRightBig 0.5s;
}
.fade-in-right-big.ng-leave {
  -webkit-animation: fadeOutLeftBig 0.5s;
  animation: fadeOutLeftBig 0.5s;
}
.fade-in-left-big.ng-enter {
  -webkit-animation: fadeInLeftBig 0.5s;
  animation: fadeInLeftBig 0.5s;
}
.fade-in-left-big.ng-leave {
  -webkit-animation: fadeOutRightBig 0.5s;
  animation: fadeOutRightBig 0.5s;
}
.fade-in-up-big.ng-enter {
  -webkit-animation: fadeInUpBig 0.5s;
  animation: fadeInUpBig 0.5s;
}
.fade-in-up-big.ng-leave {
  -webkit-animation: fadeOutUpBig 0.5s;
  animation: fadeOutUpBig 0.5s;
}
.fade-in-down-big.ng-enter {
  -webkit-animation: fadeInDownBig 0.5s;
  animation: fadeInDownBig 0.5s;
}
.fade-in-down-big.ng-leave {
  -webkit-animation: fadeOutDownBig 0.5s;
  animation: fadeOutDownBig 0.5s;
}
.fade-in.ng-enter {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}
.fade-in.ng-leave {
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
}
.fade-in-right.ng-enter {
  -webkit-animation: fadeInRight 0.5s;
  animation: fadeInRight 0.5s;
}
.fade-in-right.ng-leave {
  -webkit-animation: fadeOutLeft 0.5s;
  animation: fadeOutLeft 0.5s;
}
.fade-in-left.ng-enter {
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}
.fade-in-left.ng-leave {
  -webkit-animation: fadeOutRight 0.5s;
  animation: fadeOutRight 0.5s;
}
.fade-in-up.ng-enter {
  -webkit-animation: fadeInUp 0.5s;
  animation: fadeInUp 0.5s;
}
.fade-in-up.ng-leave {
  -webkit-animation: fadeOutUp 0.5s;
  animation: fadeOutUp 0.5s;
}
.fade-in-down.ng-enter {
  -webkit-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s;
}
.fade-in-down.ng-leave {
  -webkit-animation: fadeOutDown 0.5s;
  animation: fadeOutDown 0.5s;
}
html {
  background-color: #efefef;
}
body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: 1.42857143;
}
*:focus {
  outline: 0 !important;
}
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
}
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}
.well,
pre {
  background-color: #eaebec;
  border-color: #dddee0;
}
small,
.small {
  font-size: 13px;
}
blockquote {
  border-color: #dddee0;
}
label {
  font-weight: normal;
}
sup {
  position: relative;
  top: -0.5em;
  font-size: 75%;
}
.thumbnail {
  border-color: #dddee0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .pull-none-sm {
    float: none !important;
  }
  .pull-right-sm {
    float: right !important;
  }
  .pull-left-sm {
    float: left !important;
  }
  .hidden-sm.show {
    display: inherit !important;
  }
}
@media (max-width: 767px) {
  .pull-none-xs {
    float: none !important;
  }
  .pull-right-xs {
    float: right !important;
  }
  .pull-left-xs {
    float: left !important;
  }
  .hidden-xs.show {
    display: inherit !important;
  }
}
.badge,
.label {
  font-weight: bold;
  background-color: #a7abaf;
}
.badge.bg-light,
.badge.bg-white,
.label.bg-light,
.label.bg-white {
  text-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}
.label {
  padding: 0.35em .6em;
}
.badge {
  padding: 3px 6px;
}
.badge.up {
  position: relative;
  top: -10px;
  margin: 0 -0.5em;
}
.badge-lg {
  border-radius: 20px;
  font-size: 16px;
  padding: 5px 9px;
}
.badge-sm {
  padding: 2px 5px;
}
.badge-xs {
  padding: 1px 4px;
}
.label-lg {
  font-size: 16px;
  padding: 5px 9px;
}
.label-sm {
  padding: 2px 5px;
}
.label-xs {
  padding: 1px 4px;
}
.badge-white {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.35);
  padding: 2px 6px;
}
.badge-black {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: inherit;
}
.btn-default {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #ffffff;
  border-color: #dddee0;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.btn-default.btn-stroke:hover,
.btn-default.btn-stroke:focus,
.btn-default.btn-stroke:active,
.btn-default.btn-stroke.active,
.open .dropdown-toggle.btn-default {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #f2f2f2 !important;
  border-color: #c7cacd !important;
}
.btn-default.btn-addon > i {
  border: 1px solid #cdcfd1;
  background-color: #f0f0f0;
}
.btn-default.btn-stroke {
  color: #ffffff !important;
  background-color: transparent;
}
.btn-primary {
  color: #ffffff !important;
  background-color: #607d8b;
  border-color: #607d8b;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.btn-stroke:hover,
.btn-primary.btn-stroke:focus,
.btn-primary.btn-stroke:active,
.btn-primary.btn-stroke.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff !important;
  background-color: #566f7c !important;
  border-color: #4f6773 !important;
}
.btn-primary.btn-addon > i {
  border: 1px solid #536d79;
  background-color: #536d79;
}
.btn-primary.btn-stroke {
  color: #607d8b !important;
  background-color: transparent;
}
.btn-success {
  color: #ffffff !important;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.btn-stroke:hover,
.btn-success.btn-stroke:focus,
.btn-success.btn-stroke:active,
.btn-success.btn-stroke.active,
.open .dropdown-toggle.btn-success {
  color: #ffffff !important;
  background-color: #449d48 !important;
  border-color: #409343 !important;
}
.btn-success.btn-addon > i {
  border: 1px solid #439a46;
  background-color: #439a46;
}
.btn-success.btn-stroke {
  color: #4caf50 !important;
  background-color: transparent;
}
.btn-info {
  color: #ffffff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.btn-stroke:hover,
.btn-info.btn-stroke:focus,
.btn-info.btn-stroke:active,
.btn-info.btn-stroke.active,
.open .dropdown-toggle.btn-info {
  color: #ffffff !important;
  background-color: #0d8aee !important;
  border-color: #0c82df !important;
}
.btn-info.btn-addon > i {
  border: 1px solid #0d87e9;
  background-color: #0d87e9;
}
.btn-info.btn-stroke {
  color: #2196f3 !important;
  background-color: transparent;
}
.btn-warning {
  color: #ffffff !important;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.btn-stroke:hover,
.btn-warning.btn-stroke:focus,
.btn-warning.btn-stroke:active,
.btn-warning.btn-stroke.active,
.open .dropdown-toggle.btn-warning {
  color: #ffffff !important;
  background-color: #ecb100 !important;
  border-color: #dda600 !important;
}
.btn-warning.btn-addon > i {
  border: 1px solid #e7ae00;
  background-color: #e7ae00;
}
.btn-warning.btn-stroke {
  color: #ffc107 !important;
  background-color: transparent;
}
.btn-danger {
  color: #ffffff !important;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.btn-stroke:hover,
.btn-danger.btn-stroke:focus,
.btn-danger.btn-stroke:active,
.btn-danger.btn-stroke.active,
.open .dropdown-toggle.btn-danger {
  color: #ffffff !important;
  background-color: #f32c1e !important;
  border-color: #f21f0f !important;
}
.btn-danger.btn-addon > i {
  border: 1px solid #f22819;
  background-color: #f22819;
}
.btn-danger.btn-stroke {
  color: #f44336 !important;
  background-color: transparent;
}
.btn-accent {
  color: #ffffff !important;
  background-color: #7e57c2;
  border-color: #7e57c2;
}
.btn-accent:hover,
.btn-accent:focus,
.btn-accent:active,
.btn-accent.active,
.btn-accent.btn-stroke:hover,
.btn-accent.btn-stroke:focus,
.btn-accent.btn-stroke:active,
.btn-accent.btn-stroke.active,
.open .dropdown-toggle.btn-accent {
  color: #ffffff !important;
  background-color: #7044bb !important;
  border-color: #6940b0 !important;
}
.btn-accent.btn-addon > i {
  border: 1px solid #6d43b8;
  background-color: #6d43b8;
}
.btn-accent.btn-stroke {
  color: #7e57c2 !important;
  background-color: transparent;
}
.btn-dark {
  color: #ffffff !important;
  background-color: #475069;
  border-color: #475069;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.btn-stroke:hover,
.btn-dark.btn-stroke:focus,
.btn-dark.btn-stroke:active,
.btn-dark.btn-stroke.active,
.open .dropdown-toggle.btn-dark {
  color: #ffffff !important;
  background-color: #3d445a !important;
  border-color: #373d51 !important;
}
.btn-dark.btn-addon > i {
  border: 1px solid #3b4257;
  background-color: #3b4257;
}
.btn-dark.btn-stroke {
  color: #475069 !important;
  background-color: transparent;
}
.btn-light {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #eaebec;
  border-color: #eaebec;
}
.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light.btn-stroke:hover,
.btn-light.btn-stroke:focus,
.btn-light.btn-stroke:active,
.btn-light.btn-stroke.active,
.open .dropdown-toggle.btn-light {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #dddee0 !important;
  border-color: #d5d7d9 !important;
}
.btn-light.btn-addon > i {
  border: 1px solid #dadcdd;
  background-color: #dadcdd;
}
.btn-light.btn-stroke {
  color: #eaebec !important;
  background-color: transparent;
}
.btn {
  font-weight: 500;
  border-radius: 3px;
  outline: 0!important;
  box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
}
.btn > i.pull-left,
.btn > i.pull-right {
  line-height: 1.428571429;
}
.btn-link {
  color: rgba(0, 0, 0, 0.87);
}
.btn-link.active {
  webkit-box-shadow: none;
  box-shadow: none;
}
.btn-block {
  padding-left: 12px;
  padding-right: 12px;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 3px;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: 3px;
}
.btn-rounded {
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-rounded.btn-lg {
  padding-left: 25px;
  padding-right: 25px;
}
.btn-addon i {
  margin: -7px -12px;
  margin-right: 12px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  float: left;
  position: relative;
  left: -1px;
  border-radius: 3px 0 0 3px;
}
.btn-addon i.pull-right {
  left: auto;
  right: -1px;
  margin-right: -12px;
  margin-left: 12px;
  border-radius: 0 3px 3px 0;
}
.btn-addon.btn-sm i {
  margin: -6px -10px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.btn-addon.btn-sm i.pull-right {
  margin-right: -10px;
  margin-left: 10px;
}
.btn-addon.btn-lg i {
  margin: -11px -16px;
  margin-right: 16px;
  width: 46px;
  height: 46px;
  line-height: 45px;
}
.btn-addon.btn-lg i.pull-right {
  margin-right: -16px;
  margin-left: 16px;
}
.btn-clip {
  border-color: rgba(0, 0, 0, 0.1) !important;
  background-clip: padding-box;
}
.btn-icon {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 34px;
  height: 34px;
  padding: 0;
  text-align: center;
  line-height: 34px;
}
.btn-icon.btn-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.btn-icon.btn-lg {
  width: 45px;
  height: 45px;
  line-height: 45px;
}
.btn-groups .btn {
  margin-bottom: 5px;
}
.btn-fw {
  min-width: 120px;
}
.dropdown-menu {
  border-radius: 3px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform-origin: top left;
  -moz-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}
.dropdown-menu.pull-left {
  left: 100%;
}
.dropdown-menu.pull-right {
  left: auto !important;
  -webkit-transform-origin: top right;
  -moz-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
}
.dropdown-menu.pull-up {
  top: 0;
}
.dropup .dropdown-menu {
  -webkit-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}
.dropup .dropdown-menu.pull-right {
  -webkit-transform-origin: bottom right;
  -moz-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
}
.dropdown-menu > .panel {
  border: none;
  margin: -5px 0;
}
.dropdown-menu > li > a {
  color: inherit;
  padding: 8px 16px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-image: none;
  filter: none;
  color: rgba(0, 0, 0, 0.87);
  background-color: #eaebec !important;
}
.dropdown-menu.top,
.dropdown-menu.top-left,
.dropdown-menu.top-right {
  margin-top: -2px;
}
.dropdown-menu.datepicker {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
}
.dropdown-menu.datepicker .btn {
  font-size: 12px;
}
.dropdown-menu.timepicker {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}
.dropdown-menu.timepicker .btn {
  font-size: 12px;
}
.dropdown-menu-scale {
  display: block;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.open > .dropdown-menu-scale {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.dropdown-header {
  padding: 8px 16px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  background-color: inherit !important;
}
.dropdown-submenu:hover > .dropdown-menu,
.dropdown-submenu:focus > .dropdown-menu {
  display: block;
}
.dropdown-submenu.pull-left {
  float: none !important;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.dropdown-submenu .dropdown-menu {
  left: 100%;
  top: 0;
  margin-top: -6px;
  margin-left: -1px;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
}
@media (max-width: 767px) {
  .dropdown-menu.pull-none-xs {
    left: 0;
  }
}
.form-control {
  border-color: #cfd1d4;
  border-radius: 0;
}
.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:focus {
  border-color: #a7abaf;
}
.form-control-spin {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}
.input-lg,
.form-horizontal .form-group-lg .form-control {
  height: 45px;
  border-radius: 0;
}
.input-group-addon {
  border-color: #cfd1d4;
  background-color: #eaebec;
}
.form-group-stack .form-control {
  margin-top: -1px;
  position: relative;
  border-radius: 0;
}
.form-group-stack .form-control:focus,
.form-group-stack .form-control:active {
  z-index: 1;
}
.form-validation .form-control.ng-dirty.ng-invalid {
  border-color: #f44336;
}
.form-validation .form-control.ng-dirty.ng-valid,
.form-validation .form-control.ng-dirty.ng-valid:focus {
  border-color: #4caf50;
}
.form-validation .ui-checks .ng-invalid.ng-dirty + i {
  border-color: #f44336;
}
.form-file {
  position: relative;
}
.form-file input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
@media (max-width: 992px) {
  .form-horizontal .form-group {
    margin-left: -8px;
    margin-right: -8px;
  }
}
.list-group {
  border-radius: 3px;
  background: #fff;
}
.list-group.no-radius .list-group-item {
  border-radius: 0 !important;
}
.list-group.no-borders .list-group-item {
  border: none;
}
.list-group.no-border .list-group-item {
  border-width: 1px 0;
}
.list-group.no-bg .list-group-item {
  background-color: transparent;
}
.list-group.list-group-gap {
  background: transparent;
}
.list-group-item {
  border-left-color: transparent;
  border-right-color: transparent;
  border-color: rgba(110, 115, 120, 0.045);
  background: transparent;
  padding: 12px 16px;
}
a.list-group-item {
  color: inherit;
}
a.list-group-item:hover,
a.list-group-item:focus,
a.list-group-item.hover {
  color: inherit;
  background-color: rgba(110, 115, 120, 0.075);
}
.list-group-item.media {
  margin-top: 0;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  color: inherit;
  background-color: rgba(110, 115, 120, 0.075);
  border-color: transparent;
  border-bottom-color: 1px solid rgba(110, 115, 120, 0.075);
}
.list-group-item.active a,
.list-group-item.active:hover a,
.list-group-item.active:focus a {
  color: inherit;
}
.list-group-alt .list-group-item:nth-child(2n+2) {
  background-color: rgba(110, 115, 120, 0.075);
}
.list-group-alt .list-group-item.active:nth-child(2n+2) {
  background-color: rgba(110, 115, 120, 0.075);
}
.list-group-lg .list-group-item {
  padding: 16px 24px;
}
.list-group-md .list-group-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.list-group-sm .list-group-item {
  padding: 10px 12px;
}
.list-group-gap .list-group-item {
  margin-bottom: 5px;
  border-radius: 3px;
  background: #fff;
}
.modal.fade .left {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal.fade .right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal.fade .top {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal.fade .bottom {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal.in .left,
.modal.in .right,
.modal.in .top,
.modal.in .bottom {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.modal > .left {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
}
.modal > .right {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
}
.modal > .top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
}
.modal > .bottom {
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-backdrop {
  background-color: #475069;
}
.modal-backdrop.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.modal-over {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
.modal-center {
  position: absolute;
  left: 50%;
  top: 50%;
}
.nav > li > a:hover,
.nav > li > a:focus,
.nav > li.active > a {
  background-color: inherit;
}
.nav > li > a {
  padding: 10px 16px;
}
.nav.nav-lg > li > a {
  padding: 20px 20px;
}
.nav.nav-md > li > a {
  padding: 16px 16px;
}
.nav.nav-sm > li > a {
  font-size: 13px;
  padding: 6px 12px;
}
.nav.nav-xs > li > a {
  font-size: 12px;
  padding: 4px 10px;
}
.nav.nav-xxs > li > a {
  font-size: 12px;
  padding: 1px 10px;
}
.nav.nav-rounded > li > a {
  border-radius: 20px;
}
.nav.no-radius > li > a {
  border-radius: 0;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: inherit;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:active {
  color: rgba(0, 0, 0, 0.87);
  background-color: #dddee0;
}
.nav-pills > li.active > a > .text-muted {
  color: rgba(0, 0, 0, 0.87);
}
.nav-tabs {
  border-color: #dddee0;
}
.nav-tabs > li > a {
  border-radius: 3px 3px 0 0;
  border-bottom-color: #dddee0;
}
.nav-tabs > li:hover > a,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover .nav-tabs > li.active > a:focus {
  border-color: #dddee0;
  background-color: #fff;
}
.nav-tabs > li.active > a {
  border-bottom-color: #fff !important;
}
.nav-tabs.nav-justified > li {
  display: table-cell;
  width: 1%;
}
.nav-tabs.nav-justified > li > a {
  margin-bottom: 0;
  border-radius: 3px 3px 0 0;
}
.nav-lines {
  margin-bottom: -1px;
  border-width: 0 !important;
}
.nav-lines.nav-justified {
  border-bottom: 2px solid #2196f3;
  margin-bottom: -2px;
}
.nav-lines > li {
  float: left;
  border-color: inherit;
  opacity: 0.6;
}
.nav-lines > li:after {
  content: "";
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.nav-lines > li > a {
  background-color: transparent !important;
  border-width: 0 !important;
}
.nav-lines > li.active {
  opacity: 1;
}
.nav-lines > li.active:after {
  left: 0%;
  right: 0%;
  border-bottom-color: inherit;
}
.nav-lines.nav-lines-top > li:after {
  bottom: auto;
  top: 0;
}
.nav-lists a {
  border-bottom: 1px solid #dddee0;
}
.tab-container {
  margin-bottom: 15px;
}
.tab-container .tab-content {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #dddee0;
  border-top-width: 0;
  border-radius: 0 0 3px 3px;
}
.navbar {
  border: none;
  margin: 0;
  position: relative;
  z-index: 50;
  min-height: 64px;
}
.navbar .navbar-nav > li > a,
.navbar .navbar-tool > li > a,
.navbar .navbar-item,
.navbar .navbar-brand {
  padding: 0 16px;
  line-height: 64px;
}
.navbar .navbar-tool > li {
  float: left;
}
.navbar .navbar-brand {
  min-height: 64px;
  font-size: 20px;
  font-weight: bold;
  display: block;
  float: none;
}
.navbar .navbar-brand:hover {
  text-decoration: none;
}
.navbar .navbar-brand img,
.navbar .navbar-brand svg {
  display: inline-block;
  vertical-align: -10%;
}
@media (max-width: 991px) {
  .navbar {
    min-height: 56px;
  }
  .navbar .navbar-brand {
    min-height: 56px;
  }
  .navbar .navbar-nav > li > a,
  .navbar .navbar-tool > li > a,
  .navbar .navbar-item,
  .navbar .navbar-brand {
    line-height: 56px;
  }
}
.pagination > li > a {
  border-color: #dddee0;
}
.pagination > li > a:hover,
.pagination > li > a:focus {
  border-color: #dddee0;
  background-color: #eaebec;
}
.panel {
  border-radius: 3px;
}
.panel .accordion-toggle {
  font-size: 14px;
  display: block;
  cursor: pointer;
}
.panel .list-group-item {
  border-color: #eaebec;
}
.panel.no-borders {
  border-width: 0;
}
.panel.no-borders .panel-heading,
.panel.no-borders .panel-footer {
  border-width: 0;
}
.panel-card {
  border-width: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.panel-heading {
  padding: 16px 24px;
  border-radius: 3px 3px 0 0;
}
.panel-default .panel-heading {
  background-color: #f2f3f3;
}
.panel-default .panel-heading.bg-white {
  background-color: #fff;
}
.panel-heading.no-border {
  margin: -1px -1px 0 -1px;
  border: none;
}
.panel-heading .nav {
  margin: -12px -16px;
}
.panel-heading .list-group {
  background: transparent;
}
.panel-heading.panel-heading-sm {
  padding-top: 8px;
  padding-bottom: 8px;
}
.panel-heading.panel-heading-xs {
  padding-top: 6px;
  padding-bottom: 6px;
}
.panel-body {
  padding: 16px 24px;
}
.panel-footer {
  padding: 16px 24px;
  border-color: #eaebec;
  border-radius: 0 0 3px 3px;
  background-color: #f2f3f3;
}
.panel-footer.panel-footer-sm {
  padding-top: 8px;
  padding-bottom: 8px;
}
.panel-footer.panel-footer-xs {
  padding-top: 6px;
  padding-bottom: 6px;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #eaebec;
}
.panel-default {
  border-color: #dddee0;
}
.panel-default > .panel-heading,
.panel-default > .panel-footer {
  border-color: #eaebec;
}
.panel-primary {
  border-color: #d8dfe3;
}
.panel-primary > .panel-heading {
  color: #36474f;
  background-color: #e7ecee;
  border-color: #d8dfe3;
}
.panel-info {
  border-color: #c6e4fc;
}
.panel-info > .panel-heading {
  color: #0960a5;
  background-color: #deeffd;
  border-color: #c6e4fc;
}
.panel-success {
  border-color: #d3ebd4;
}
.panel-success > .panel-heading {
  color: #2d682f;
  background-color: #e4f3e5;
  border-color: #d3ebd4;
}
.panel-warning {
  border-color: #ffefc0;
}
.panel-warning > .panel-heading {
  color: #a07800;
  background-color: #fff6da;
  border-color: #ffefc0;
}
.panel-danger {
  border-color: #fcccc9;
}
.panel-danger > .panel-heading {
  color: #ba160a;
  background-color: #fde3e1;
  border-color: #fcccc9;
}
.panel-dark {
  border-color: #d5d7dd;
}
.panel-dark > .panel-heading {
  color: #1e222c;
  background-color: #e3e5e9;
  border-color: #d5d7dd;
}
.progress {
  background-color: #eaebec;
}
.progress-xxxs {
  height: 1px;
}
.progress-xxs {
  height: 2px;
}
.progress-xs {
  height: 6px;
}
.progress-sm {
  height: 12px;
}
.progress-sm .progress-bar {
  font-size: 10px;
  line-height: 12px;
}
.progress,
.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar-primary {
  background-color: #607d8b;
}
.progress-bar-info {
  background-color: #2196f3;
}
.progress-bar-success {
  background-color: #4caf50;
}
.progress-bar-warning {
  background-color: #ffc107;
}
.progress-bar-danger {
  background-color: #f44336;
}
.progress-bar-accent {
  background-color: #7e57c2;
}
.progress-bar-dark {
  background-color: #475069;
}
.progress-bar-white {
  background-color: #fff;
}
.col-0 {
  clear: left;
}
.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter [class*="col"] {
  padding: 0;
}
.row-sm {
  margin-left: -8px;
  margin-right: -8px;
}
.row-sm > div {
  padding-left: 8px;
  padding-right: 8px;
}
.row-xs {
  margin-left: -4px;
  margin-right: -4px;
}
.row-xs > div {
  padding-left: 4px;
  padding-right: 4px;
}
@media (max-width: 992px) {
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .row > div {
    padding-left: 8px;
    padding-right: 8px;
  }
  .row-2 [class*="col"] {
    width: 50%;
    float: left;
  }
  .row-2 .col-0 {
    clear: none;
  }
  .row-2 li:nth-child(odd) {
    clear: left;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .row {
    margin-left: -4px;
    margin-right: -4px;
  }
  .row > div {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.panel .table {
  border-color: #e7e8ea !important;
}
.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  padding: 12px 24px;
  border-top: 1px solid #e7e8ea;
}
.table > thead > tr > th {
  padding: 12px 24px;
  border-bottom: 1px solid #e7e8ea;
}
.table-bordered {
  border-color: #e7e8ea;
}
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th {
  border-color: #e7e8ea;
}
.table-bordered > thead > tr > th {
  border-color: #e7e8ea;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f6f6f7;
}
.table-striped > thead > th {
  background-color: #f6f6f7;
  border-right: 1px solid #e7e8ea;
}
.table-striped > thead > th:last-child {
  border-right: none;
}
[md-ink-ripple] {
  position: relative;
}
.md-btn {
  color: currentColor;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  outline: none;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  display: inline-block;
  background: transparent;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-style: inherit;
  font-variant: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.md-btn.md-flat:not([disabled]):hover,
.md-btn.md-flat:not([disabled]):focus {
  background-color: rgba(158, 158, 158, 0.2);
}
.md-btn-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.md-btn[disabled],
.md-btn.md-raised[disabled],
.md-btn.md-fab[disabled] {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
  cursor: not-allowed;
}
.md-fab {
  line-height: 44px;
}
.md-fab-offset {
  margin-top: -28px;
  margin-bottom: -28px;
}
.md-btn:focus {
  outline: none;
}
.md-btn:hover {
  text-decoration: none;
}
.md-btn.md-cornered {
  border-radius: 0;
}
.md-btn.md-icon {
  padding: 0;
  background: none;
}
.md-btn.md-raised {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.md-btn.md-fab {
  z-index: 20;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: 0.2s linear;
  transition-property: -webkit-transform, box-shadow;
  transition-property: transform, box-shadow;
}
.md-btn.md-raised:not([disabled]),
.md-btn.md-fab {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.md-btn.md-raised:not([disabled]):focus,
.md-btn.md-raised:not([disabled]):hover,
.md-btn.md-fab:not([disabled]):focus,
.md-btn.md-fab:not([disabled]):hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
.md-btn.md-fab.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
}
.md-btn.md-fab.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute;
}
.md-btn.md-fab.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute;
}
.md-btn.md-fab.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute;
}
.md-btn.md-fab md-icon {
  margin-top: 0;
}
.md-btn.md-fab.md-mini {
  width: 40px;
  height: 40px;
}
.md-btn:not([disabled]).md-raised:focus,
.md-btn:not([disabled]).md-raised:hover,
.md-btn:not([disabled]).md-fab:focus,
.md-btn:not([disabled]).md-fab:hover {
  -webkit-transform: translate3d(0, -1px, 0);
  transform: translate3d(0, -1px, 0);
}
.md-toast-open-top .md-btn.md-fab-top-left,
.md-toast-open-top .md-btn.md-fab-top-right {
  -webkit-transform: translate3d(0, 42px, 0);
  transform: translate3d(0, 42px, 0);
}
.md-toast-open-top .md-btn.md-fab-top-left:not([disabled]):focus,
.md-toast-open-top .md-btn.md-fab-top-left:not([disabled]):hover,
.md-toast-open-top .md-btn.md-fab-top-right:not([disabled]):focus,
.md-toast-open-top .md-btn.md-fab-top-right:not([disabled]):hover {
  -webkit-transform: translate3d(0, 41px, 0);
  transform: translate3d(0, 41px, 0);
}
.md-toast-open-bottom .md-btn.md-fab-bottom-left,
.md-toast-open-bottom .md-btn.md-fab-bottom-right {
  -webkit-transform: translate3d(0, -42px, 0);
  transform: translate3d(0, -42px, 0);
}
.md-toast-open-bottom .md-btn.md-fab-bottom-left:not([disabled]):focus,
.md-toast-open-bottom .md-btn.md-fab-bottom-left:not([disabled]):hover,
.md-toast-open-bottom .md-btn.md-fab-bottom-right:not([disabled]):focus,
.md-toast-open-bottom .md-btn.md-fab-bottom-right:not([disabled]):hover {
  -webkit-transform: translate3d(0, -43px, 0);
  transform: translate3d(0, -43px, 0);
}
.card {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: relative;
  margin-bottom: 24px;
}
.card-heading {
  position: relative;
  padding: 20px 24px;
}
.card-heading h2 {
  margin: 0;
  font-size: 18px;
}
.card-heading small {
  display: block;
  margin-top: 8px;
  opacity: 0.5;
}
.card-body {
  padding: 20px 24px;
}
.card-divider {
  border-bottom: 1px solid rgba(110, 115, 120, 0.15);
  margin: 0 24px;
}
.card-tools {
  position: absolute;
  right: 12px;
  top: 12px;
}
.red {
  background-color: #f44336;
}
.red-50 {
  background-color: #ffebee;
}
.red-100 {
  background-color: #ffcdd2;
}
.red-200 {
  background-color: #ef9a9a;
}
.red-300 {
  background-color: #e57373;
}
.red-400 {
  background-color: #ef5350;
}
.red-500 {
  background-color: #f44336;
}
.red-600 {
  background-color: #e53935;
}
.red-700 {
  background-color: #d32f2f;
}
.red-800 {
  background-color: #c62828;
}
.red-900 {
  background-color: #b71c1c;
}
.red-A100 {
  background-color: #ff8a80;
}
.red-A200 {
  background-color: #ff5252;
}
.red-A400 {
  background-color: #ff1744;
}
.red-A700 {
  background-color: #d50000;
}
.pink {
  background-color: #e91e63;
}
.pink-50 {
  background-color: #fce4ec;
}
.pink-100 {
  background-color: #f8bbd0;
}
.pink-200 {
  background-color: #f48fb1;
}
.pink-300 {
  background-color: #f06292;
}
.pink-400 {
  background-color: #ec407a;
}
.pink-500 {
  background-color: #e91e63;
}
.pink-600 {
  background-color: #d81b60;
}
.pink-700 {
  background-color: #c2185b;
}
.pink-800 {
  background-color: #ad1457;
}
.pink-900 {
  background-color: #880e4f;
}
.pink-A100 {
  background-color: #ff80ab;
}
.pink-A200 {
  background-color: #ff4081;
}
.pink-A400 {
  background-color: #f50057;
}
.pink-A700 {
  background-color: #c51162;
}
.purple {
  background-color: #9c27b0;
}
.purple-50 {
  background-color: #f3e5f5;
}
.purple-100 {
  background-color: #e1bee7;
}
.purple-200 {
  background-color: #ce93d8;
}
.purple-300 {
  background-color: #ba68c8;
}
.purple-400 {
  background-color: #ab47bc;
}
.purple-500 {
  background-color: #9c27b0;
}
.purple-600 {
  background-color: #8e24aa;
}
.purple-700 {
  background-color: #7b1fa2;
}
.purple-800 {
  background-color: #6a1b9a;
}
.purple-900 {
  background-color: #4a148c;
}
.purple-A100 {
  background-color: #ea80fc;
}
.purple-A200 {
  background-color: #e040fb;
}
.purple-A400 {
  background-color: #d500f9;
}
.purple-A700 {
  background-color: #aa00ff;
}
.deep-purple {
  background-color: #673ab7;
}
.deep-purple-50 {
  background-color: #ede7f6;
}
.deep-purple-100 {
  background-color: #d1c4e9;
}
.deep-purple-200 {
  background-color: #b39ddb;
}
.deep-purple-300 {
  background-color: #9575cd;
}
.deep-purple-400 {
  background-color: #7e57c2;
}
.deep-purple-500 {
  background-color: #673ab7;
}
.deep-purple-600 {
  background-color: #5e35b1;
}
.deep-purple-700 {
  background-color: #512da8;
}
.deep-purple-800 {
  background-color: #4527a0;
}
.deep-purple-900 {
  background-color: #311b92;
}
.deep-purple-A100 {
  background-color: #b388ff;
}
.deep-purple-A200 {
  background-color: #7c4dff;
}
.deep-purple-A400 {
  background-color: #651fff;
}
.deep-purple-A700 {
  background-color: #6200ea;
}
.indigo {
  background-color: #3f51b5;
}
.indigo-50 {
  background-color: #e8eaf6;
}
.indigo-100 {
  background-color: #c5cae9;
}
.indigo-200 {
  background-color: #9fa8da;
}
.indigo-300 {
  background-color: #7986cb;
}
.indigo-400 {
  background-color: #5c6bc0;
}
.indigo-500 {
  background-color: #3f51b5;
}
.indigo-600 {
  background-color: #3949ab;
}
.indigo-700 {
  background-color: #303f9f;
}
.indigo-800 {
  background-color: #283593;
}
.indigo-900 {
  background-color: #1a237e;
}
.indigo-A100 {
  background-color: #8c9eff;
}
.indigo-A200 {
  background-color: #536dfe;
}
.indigo-A400 {
  background-color: #3d5afe;
}
.indigo-A700 {
  background-color: #304ffe;
}
.blue {
  background-color: #2196f3;
}
.blue-50 {
  background-color: #e3f2fd;
}
.blue-100 {
  background-color: #bbdefb;
}
.blue-200 {
  background-color: #90caf9;
}
.blue-300 {
  background-color: #64b5f6;
}
.blue-400 {
  background-color: #42a5f5;
}
.blue-500 {
  background-color: #2196f3;
}
.blue-600 {
  background-color: #1e88e5;
}
.blue-700 {
  background-color: #1976d2;
}
.blue-800 {
  background-color: #1565c0;
}
.blue-900 {
  background-color: #0d47a1;
}
.blue-A100 {
  background-color: #82b1ff;
}
.blue-A200 {
  background-color: #448aff;
}
.blue-A400 {
  background-color: #2979ff;
}
.blue-A700 {
  background-color: #2962ff;
}
.light-blue {
  background-color: #03a9f4;
}
.light-blue-50 {
  background-color: #e1f5fe;
}
.light-blue-100 {
  background-color: #b3e5fc;
}
.light-blue-200 {
  background-color: #81d4fa;
}
.light-blue-300 {
  background-color: #4fc3f7;
}
.light-blue-400 {
  background-color: #29b6f6;
}
.light-blue-500 {
  background-color: #03a9f4;
}
.light-blue-600 {
  background-color: #039be5;
}
.light-blue-700 {
  background-color: #0288d1;
}
.light-blue-800 {
  background-color: #0277bd;
}
.light-blue-900 {
  background-color: #01579b;
}
.light-blue-A100 {
  background-color: #80d8ff;
}
.light-blue-A200 {
  background-color: #40c4ff;
}
.light-blue-A400 {
  background-color: #00b0ff;
}
.light-blue-A700 {
  background-color: #0091ea;
}
.cyan {
  background-color: #00bcd4;
}
.cyan-50 {
  background-color: #e0f7fa;
}
.cyan-100 {
  background-color: #b2ebf2;
}
.cyan-200 {
  background-color: #80deea;
}
.cyan-300 {
  background-color: #4dd0e1;
}
.cyan-400 {
  background-color: #26c6da;
}
.cyan-500 {
  background-color: #00bcd4;
}
.cyan-600 {
  background-color: #00acc1;
}
.cyan-700 {
  background-color: #0097a7;
}
.cyan-800 {
  background-color: #00838f;
}
.cyan-900 {
  background-color: #006064;
}
.cyan-A100 {
  background-color: #84ffff;
}
.cyan-A200 {
  background-color: #18ffff;
}
.cyan-A400 {
  background-color: #00e5ff;
}
.cyan-A700 {
  background-color: #00b8d4;
}
.teal {
  background-color: #009688;
}
.teal-50 {
  background-color: #e0f2f1;
}
.teal-100 {
  background-color: #b2dfdb;
}
.teal-200 {
  background-color: #80cbc4;
}
.teal-300 {
  background-color: #4db6ac;
}
.teal-400 {
  background-color: #26a69a;
}
.teal-500 {
  background-color: #009688;
}
.teal-600 {
  background-color: #00897b;
}
.teal-700 {
  background-color: #00796b;
}
.teal-800 {
  background-color: #00695c;
}
.teal-900 {
  background-color: #004d40;
}
.teal-A100 {
  background-color: #a7ffeb;
}
.teal-A200 {
  background-color: #64ffda;
}
.teal-A400 {
  background-color: #1de9b6;
}
.teal-A700 {
  background-color: #00bfa5;
}
.green {
  background-color: #4caf50;
}
.green-50 {
  background-color: #e8f5e9;
}
.green-100 {
  background-color: #c8e6c9;
}
.green-200 {
  background-color: #a5d6a7;
}
.green-300 {
  background-color: #81c784;
}
.green-400 {
  background-color: #66bb6a;
}
.green-500 {
  background-color: #4caf50;
}
.green-600 {
  background-color: #43a047;
}
.green-700 {
  background-color: #388e3c;
}
.green-800 {
  background-color: #2e7d32;
}
.green-900 {
  background-color: #1b5e20;
}
.green-A100 {
  background-color: #b9f6ca;
}
.green-A200 {
  background-color: #69f0ae;
}
.green-A400 {
  background-color: #00e676;
}
.green-A700 {
  background-color: #00c853;
}
.light-green {
  background-color: #8bc34a;
}
.light-green-50 {
  background-color: #f1f8e9;
}
.light-green-100 {
  background-color: #dcedc8;
}
.light-green-200 {
  background-color: #c5e1a5;
}
.light-green-300 {
  background-color: #aed581;
}
.light-green-400 {
  background-color: #9ccc65;
}
.light-green-500 {
  background-color: #8bc34a;
}
.light-green-600 {
  background-color: #7cb342;
}
.light-green-700 {
  background-color: #689f38;
}
.light-green-800 {
  background-color: #558b2f;
}
.light-green-900 {
  background-color: #33691e;
}
.light-green-A100 {
  background-color: #ccff90;
}
.light-green-A200 {
  background-color: #b2ff59;
}
.light-green-A400 {
  background-color: #76ff03;
}
.light-green-A700 {
  background-color: #64dd17;
}
.lime {
  background-color: #cddc39;
}
.lime-50 {
  background-color: #f9fbe7;
}
.lime-100 {
  background-color: #f0f4c3;
}
.lime-200 {
  background-color: #e6ee9c;
}
.lime-300 {
  background-color: #dce775;
}
.lime-400 {
  background-color: #d4e157;
}
.lime-500 {
  background-color: #cddc39;
}
.lime-600 {
  background-color: #c0ca33;
}
.lime-700 {
  background-color: #afb42b;
}
.lime-800 {
  background-color: #9e9d24;
}
.lime-900 {
  background-color: #827717;
}
.lime-A100 {
  background-color: #f4ff81;
}
.lime-A200 {
  background-color: #eeff41;
}
.lime-A400 {
  background-color: #c6ff00;
}
.lime-A700 {
  background-color: #aeea00;
}
.yellow {
  background-color: #ffeb3b;
}
.yellow-50 {
  background-color: #fffde7;
}
.yellow-100 {
  background-color: #fff9c4;
}
.yellow-200 {
  background-color: #fff59d;
}
.yellow-300 {
  background-color: #fff176;
}
.yellow-400 {
  background-color: #ffee58;
}
.yellow-500 {
  background-color: #ffeb3b;
}
.yellow-600 {
  background-color: #fdd835;
}
.yellow-700 {
  background-color: #fbc02d;
}
.yellow-800 {
  background-color: #f9a825;
}
.yellow-900 {
  background-color: #f57f17;
}
.yellow-A100 {
  background-color: #ffff8d;
}
.yellow-A200 {
  background-color: #ffff00;
}
.yellow-A400 {
  background-color: #ffea00;
}
.yellow-A700 {
  background-color: #ffd600;
}
.amber {
  background-color: #ffc107;
}
.amber-50 {
  background-color: #fff8e1;
}
.amber-100 {
  background-color: #ffecb3;
}
.amber-200 {
  background-color: #ffe082;
}
.amber-300 {
  background-color: #ffd54f;
}
.amber-400 {
  background-color: #ffca28;
}
.amber-500 {
  background-color: #ffc107;
}
.amber-600 {
  background-color: #ffb300;
}
.amber-700 {
  background-color: #ffa000;
}
.amber-800 {
  background-color: #ff8f00;
}
.amber-900 {
  background-color: #ff6f00;
}
.amber-A100 {
  background-color: #ffe57f;
}
.amber-A200 {
  background-color: #ffd740;
}
.amber-A400 {
  background-color: #ffc400;
}
.amber-A700 {
  background-color: #ffab00;
}
.orange {
  background-color: #ff9800;
}
.orange-50 {
  background-color: #fff3e0;
}
.orange-100 {
  background-color: #ffe0b2;
}
.orange-200 {
  background-color: #ffcc80;
}
.orange-300 {
  background-color: #ffb74d;
}
.orange-400 {
  background-color: #ffa726;
}
.orange-500 {
  background-color: #ff9800;
}
.orange-600 {
  background-color: #fb8c00;
}
.orange-700 {
  background-color: #f57c00;
}
.orange-800 {
  background-color: #ef6c00;
}
.orange-900 {
  background-color: #e65100;
}
.orange-A100 {
  background-color: #ffd180;
}
.orange-A200 {
  background-color: #ffab40;
}
.orange-A400 {
  background-color: #ff9100;
}
.orange-A700 {
  background-color: #ff6d00;
}
.deep-orange {
  background-color: #ff5722;
}
.deep-orange-50 {
  background-color: #fbe9e7;
}
.deep-orange-100 {
  background-color: #ffccbc;
}
.deep-orange-200 {
  background-color: #ffab91;
}
.deep-orange-300 {
  background-color: #ff8a65;
}
.deep-orange-400 {
  background-color: #ff7043;
}
.deep-orange-500 {
  background-color: #ff5722;
}
.deep-orange-600 {
  background-color: #f4511e;
}
.deep-orange-700 {
  background-color: #e64a19;
}
.deep-orange-800 {
  background-color: #d84315;
}
.deep-orange-900 {
  background-color: #bf360c;
}
.deep-orange-A100 {
  background-color: #ff9e80;
}
.deep-orange-A200 {
  background-color: #ff6e40;
}
.deep-orange-A400 {
  background-color: #ff3d00;
}
.deep-orange-A700 {
  background-color: #dd2c00;
}
.brown {
  background-color: #795548;
}
.brown-50 {
  background-color: #efebe9;
}
.brown-100 {
  background-color: #d7ccc8;
}
.brown-200 {
  background-color: #bcaaa4;
}
.brown-300 {
  background-color: #a1887f;
}
.brown-400 {
  background-color: #8d6e63;
}
.brown-500 {
  background-color: #795548;
}
.brown-600 {
  background-color: #6d4c41;
}
.brown-700 {
  background-color: #5d4037;
}
.brown-800 {
  background-color: #4e342e;
}
.brown-900 {
  background-color: #3e2723;
}
.blue-grey {
  background-color: #607d8b;
}
.blue-grey-50 {
  background-color: #eceff1;
}
.blue-grey-100 {
  background-color: #cfd8dc;
}
.blue-grey-200 {
  background-color: #b0bec5;
}
.blue-grey-300 {
  background-color: #90a4ae;
}
.blue-grey-400 {
  background-color: #78909c;
}
.blue-grey-500 {
  background-color: #607d8b;
}
.blue-grey-600 {
  background-color: #546e7a;
}
.blue-grey-700 {
  background-color: #455a64;
}
.blue-grey-800 {
  background-color: #37474f;
}
.blue-grey-900 {
  background-color: #263238;
}
.grey {
  background-color: #9e9e9e;
}
.grey-50 {
  background-color: #fafafa;
}
.grey-100 {
  background-color: #f5f5f5;
}
.grey-200 {
  background-color: #eeeeee;
}
.grey-300 {
  background-color: #e0e0e0;
}
.grey-400 {
  background-color: #bdbdbd;
}
.grey-500 {
  background-color: #9e9e9e;
}
.grey-600 {
  background-color: #757575;
}
.grey-700 {
  background-color: #616161;
}
.grey-800 {
  background-color: #424242;
}
.grey-900 {
  background-color: #212121;
}
.red,
.red-500,
.red-600,
.red-700,
.red-A200,
.red-A400,
.red-A700,
.pink,
.pink-500,
.pink-600,
.pink-A200,
.pink-A400,
.pink-A700,
.purple-300,
.purple-400,
.purple-A200,
.purple-A400,
.purple-A700,
.deep-purple-300,
.deep-purple-400,
.deep-purple-A200,
.indigo-300,
.indigo-400,
.indigo-A200,
.indigo-A400,
.blue,
.blue-500,
.blue-600,
.blue-700,
.blue-A200,
.blue-A400,
.blue-A700,
.light-blue,
.light-blue-500,
.light-blue-600,
.light-blue-700,
.light-blue-800,
.light-blue-A700,
.cyan,
.cyan-500,
.cyan-600,
.cyan-700,
.cyan-800,
.teal,
.teal-500,
.teal-600,
.teal-700,
.green,
.green-500,
.green-600,
.green-700,
.light-green-800,
.light-green-900,
.lime-900,
.orange-800,
.orange-900,
.deep-orange,
.deep-orange-500,
.deep-orange-600,
.deep-orange-700,
.deep-orange-800,
.deep-orange-900,
.deep-orange-A400,
.deep-orange-A700,
.brown-300,
.brown-400,
.blue-grey,
.blue-grey-400,
.blue-grey-500 {
  color: #ffffff;
}
.red-800,
.red-900,
.pink-700,
.pink-800,
.pink-900,
.purple,
.purple-500,
.purple-600,
.purple-700,
.purple-800,
.purple-900,
.deep-purple,
.deep-purple-500,
.deep-purple-600,
.deep-purple-700,
.deep-purple-800,
.deep-purple-900,
.deep-purple-A400,
.deep-purple-A700,
.indigo,
.indigo-500,
.indigo-600,
.indigo-700,
.indigo-800,
.indigo-900,
.indigo-A700,
.blue-800,
.blue-900,
.light-blue-900,
.cyan-900,
.teal-800,
.teal-900,
.green-800,
.green-900,
.brown,
.brown-500,
.brown-600,
.brown-700,
.brown-800,
.brown-900,
.blue-grey-600,
.blue-grey-700,
.blue-grey-800,
.blue-grey-900,
.grey-600,
.grey-700,
.grey-800,
.grey-900 {
  color: rgba(255, 255, 255, 0.87);
}
.orange-A700 {
  color: #000000;
}
.md-list {
  border-radius: 3px;
}
.md-list-item {
  display: block;
  position: relative;
  padding: 16px;
}
.md-list-item:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
}
.md-list-item:last-child.md-list-item:after {
  border: none;
}
.md-list-item.inset.md-list-item:after {
  left: 72px;
}
.md-list-item-left {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  float: left;
  text-align: center;
  line-height: 40px;
}
.md-list-item-content {
  overflow: hidden;
}
.md-list-item-content h3 {
  margin: 0 0 3px 0;
  font-weight: normal;
}
.md-form-group {
  padding: 18px 0 24px 0;
  position: relative;
}
.md-input {
  background: transparent;
  position: relative;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  width: 100%;
  height: 34px;
  padding: 2px;
  color: inherit;
}
.md-input:focus,
.md-input.focus {
  border-color: #607d8b;
  border-bottom-width: 2px;
  padding-bottom: 1px;
}
.md-input:focus ~ label,
.md-input.focus ~ label {
  color: #607d8b;
  opacity: 1;
  top: 0 !important;
  font-size: 0.85em !important;
}
.float-label .md-input ~ label {
  top: 20px;
  font-size: 1em;
}
.float-label .md-input.ng-dirty ~ label,
.float-label .md-input.has-value ~ label {
  top: 0;
  font-size: 0.85em;
}
.md-input ~ label {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 0.85em;
  position: absolute;
  z-index: 0;
  opacity: 0.5;
  display: inline-block;
  top: 0px;
  left: 0;
}
.md-input.disabled,
.md-input[disabled] {
  opacity: 0.5;
}
textarea.md-input {
  height: auto;
}
.md-input-white:focus,
.md-input-white.focus {
  border-color: #fff;
}
.md-input-white:focus ~ label,
.md-input-white.focus ~ label {
  color: #fff;
}
.md-input-msg {
  position: absolute;
  bottom: 0;
  line-height: 24px;
  font-size: 0.85em;
}
.md-input-msg.right {
  right: 0;
}
.md-check {
  cursor: pointer;
  padding-left: 20px;
  margin: 0;
  display: inline-block;
  position: relative;
}
.md-check input {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 1;
  opacity: 0;
  margin-left: -20px;
}
.md-check input:checked + i:before {
  border-width: 0;
  background-color: inherit;
}
.md-check input:checked + span .active {
  display: inherit;
}
.md-check input[type="radio"] + i,
.md-check input[type="radio"] + i:before {
  border-radius: 50%;
}
.md-check input[type="checkbox"]:checked + i:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 2px;
  display: table;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  content: ' ';
}
.md-check input[type="radio"]:checked + i:after {
  position: absolute;
  left: 6px;
  top: 6px;
  display: table;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  content: ' ';
}
.md-check input[disabled] + i:before,
fieldset[disabled] .md-check input + i:before {
  border-color: rgba(0, 0, 0, 0.26);
}
.md-check input[disabled]:checked + i:before {
  background-color: rgba(0, 0, 0, 0.26);
}
.md-check > i {
  width: 0;
  height: 18px;
  line-height: 1;
  margin-left: -20px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: relative;
}
.md-check > i:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.md-check > i.no-icon:after {
  display: none !important;
}
.md-switch {
  cursor: pointer;
  padding-left: 36px;
  margin: 0;
  min-height: 20px;
}
.md-switch input {
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 20px;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
  margin-left: -36px;
}
.md-switch input:checked + i:before {
  background: inherit;
  opacity: 0.5;
}
.md-switch input:checked + i:after {
  background: inherit;
  left: 16px;
}
.md-switch input[disabled] + i:before,
fieldset[disabled] .md-switch input + i:before {
  background-color: rgba(0, 0, 0, 0.12);
}
.md-switch input[disabled] + i:after,
fieldset[disabled] .md-switch input + i:after {
  background-color: #bdbdbd;
}
.md-switch i {
  width: 0px;
  height: 18px;
  line-height: 1;
  margin-left: -36px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 44px;
  position: relative;
}
.md-switch i:before {
  content: "";
  left: 1px;
  width: 34px;
  top: 3px;
  height: 14px;
  border-radius: 8px;
  position: absolute;
  background-color: #9e9e9e;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.md-switch i:after {
  content: "";
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  outline: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.md-whiteframe-z0 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
.md-whiteframe-z1 {
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.md-whiteframe-z2 {
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
}
.md-whiteframe-z3 {
  box-shadow: 0px 17px 50px rgba(0, 0, 0, 0.19);
}
.md-whiteframe-z4 {
  box-shadow: 0px 16px 28px 0 rgba(0, 0, 0, 0.22);
}
.md-whiteframe-z5 {
  box-shadow: 0px 27px 24px 0 rgba(0, 0, 0, 0.2);
}
.arrow {
  position: absolute;
  z-index: 10;
}
.arrow:before,
.arrow:after {
  position: absolute;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-width: 9px;
  border-color: transparent;
  border-style: solid;
}
.arrow:after {
  border-width: 8px;
}
.arrow.top {
  top: -9px;
  left: 50%;
  margin-left: -9px;
}
.arrow.top.pull-in {
  top: -4px;
}
.arrow.top:before {
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-top-width: 0;
}
.arrow.top:after {
  top: 1px;
  left: 1px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: inherit;
  border-left-color: transparent;
  border-top-width: 0;
}
.arrow.right {
  top: 50%;
  right: 0;
  margin-top: -9px;
}
.arrow.right.pull-in {
  right: 5px;
}
.arrow.right:before {
  border-left-color: rgba(0, 0, 0, 0.1);
  border-right-width: 0;
}
.arrow.right:after {
  top: 1px;
  left: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
  border-right-width: 0;
}
.arrow.bottom {
  bottom: 0;
  left: 50%;
  margin-left: -9px;
}
.arrow.bottom.pull-in {
  bottom: 5px;
}
.arrow.bottom:before {
  border-top-color: rgba(0, 0, 0, 0.1);
  border-bottom-width: 0;
}
.arrow.bottom:after {
  top: 0px;
  left: 1px;
  border-top-color: inherit;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-bottom-width: 0;
}
.arrow.left {
  top: 50%;
  left: -9px;
  margin-top: -9px;
}
.arrow.left.pull-in {
  left: -4px;
}
.arrow.left:before {
  border-right-color: rgba(0, 0, 0, 0.1);
  border-left-width: 0;
}
.arrow.left:after {
  top: 1px;
  left: 1px;
  border-top-color: transparent;
  border-right-color: inherit;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-left-width: 0;
}
.arrow.pull-left {
  left: 18px;
}
.arrow.pull-right {
  left: auto;
  right: 26px;
}
.arrow.pull-top {
  top: 18px;
}
.arrow.pull-bottom {
  top: auto;
  bottom: 26px;
}
.arrow.b-primary:before,
.arrow.b-info:before,
.arrow.b-success:before,
.arrow.b-warning:before,
.arrow.b-danger:before,
.arrow.b-accent:before,
.arrow.b-dark:before {
  border-color: transparent;
}
.box {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}
.box .box-row {
  display: table-row;
  height: 100%;
}
.box .box-row .box-cell {
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
.ie .box .box-row .box-cell {
  display: table-cell;
}
.box .box-col {
  display: table-cell;
  vertical-align: top;
}
.box .box-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.box > [class*="col-"],
.box > [class*=" col-"] {
  vertical-align: top;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
}
.v-m {
  vertical-align: middle !important;
}
.v-t {
  vertical-align: top !important;
}
.v-b {
  vertical-align: bottom !important;
}
.v-c {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
@media (min-width: 1200px) {
  .box > [class*="col-lg"],
  .box > [class*=" col-lg"] {
    display: table-cell;
  }
}
@media (min-width: 992px) {
  .box > [class*="col-md"],
  .box > [class*=" col-md"] {
    display: table-cell;
  }
}
@media (min-width: 768px) {
  .box > [class*="col-sm"],
  .box > [class*=" col-sm"] {
    display: table-cell;
  }
}
@media (max-width: 767px) {
  .box > [class*="col-xs"],
  .box > [class*=" col-xs"] {
    display: table-cell;
  }
}
.ui-checks {
  padding-left: 20px;
  cursor: pointer;
}
.ui-checks input {
  opacity: 0;
  position: absolute;
  margin-left: -20px;
}
.ui-checks input:checked + i {
  border-color: #cfd1d4;
}
.ui-checks input:checked + i:before {
  left: 4px;
  top: 4px;
  width: 6px;
  height: 6px;
  background-color: #2196f3;
}
.ui-checks input:checked + span .active {
  display: inherit;
}
.ui-checks input[type="radio"] + i,
.ui-checks input[type="radio"] + i:before {
  border-radius: 50%;
}
.ui-checks input[disabled] + i,
fieldset[disabled] .ui-checks input + i {
  border-color: #dddee0;
}
.ui-checks input[disabled] + i:before,
fieldset[disabled] .ui-checks input + i:before {
  background-color: #dddee0;
}
.ui-checks > i {
  width: 16px;
  height: 16px;
  line-height: 1;
  border: 1px solid #cfd1d4;
  background-color: #fff;
  margin-left: -20px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  position: relative;
}
.ui-checks > i:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0px;
  height: 0px;
  background-color: transparent;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ui-checks > span {
  margin-left: -20px;
}
.ui-checks > span .active {
  display: none;
}
.ui-checks-md input:checked + i:before {
  left: 5px;
  top: 5px;
}
.ui-checks-md > i {
  width: 18px;
  height: 18px;
}
.ui-checks-lg input:checked + i:before {
  width: 12px;
  height: 12px;
  left: 8px;
  top: 8px;
}
.ui-checks-lg > i {
  width: 30px;
  height: 30px;
}
/*layout*/
html,
body {
  width: 100%;
  height: 100%;
}
body {
  overflow: hidden;
}
.app {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}
.modal-open .app {
  overflow: hidden;
}
.app-aside {
  position: absolute !important;
  top: 0;
  bottom: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: transparent;
  box-shadow: 0px 64px 5px 0 rgba(0, 0, 0, 0.26);
  z-index: 60;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.app-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
}
@media (min-width: 992px) {
  .app-aside {
    display: block !important;
    width: 64px;
  }
  .app-aside.folded {
    width: 64px;
  }
  .app-aside.folded ~ .app-content {
    margin-left: 64px;
  }
  .app-aside ~ .app-content {
    margin-left: 64px;
  }
  .app-aside.md-sidenav-left {
    -webkit-transform: translate3d(0%, 0, 0) !important;
    transform: translate3d(0%, 0, 0) !important;
    min-width: 0;
  }
  .app-aside .left {
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
  }
}
@media (max-width: 991px) {
  .app-aside .left {
    max-width: 304px;
  }
  .app-padding {
    padding: 4px 4px;
  }
}
nav {
  overflow-x: hidden;
}
nav ul.nav li {
  position: relative;
}
nav ul.nav li li a {
  line-height: 36px;
  padding-left: 72px;
}
nav ul.nav li li a .badge,
nav ul.nav li li a .label {
  margin-top: 10px;
}
nav ul.nav li li li a {
  padding-left: 88px;
}
nav ul.nav li li li li a {
  padding-left: 106px;
}
nav ul.nav li a,
nav ul.nav li .nav-item {
  font-weight: normal;
  text-transform: none;
  text-align: left;
  display: block;
  padding: 0 16px;
  line-height: 40px;
  position: relative;
  border-radius: 0 !important;
}
nav ul.nav li a .text,
nav ul.nav li .nav-item .text {
  margin-left: 16px;
}
nav ul.nav li a .up,
nav ul.nav li .nav-item .up {
  margin-left: 16px;
  font-style: normal;
  z-index: 1;
}
nav ul.nav li a > .pull-right i,
nav ul.nav li .nav-item > .pull-right i {
  margin-left: 16px;
  -webkit-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}
nav ul.nav li a > .icon,
nav ul.nav li .nav-item > .icon {
  float: left;
  line-height: inherit;
  margin-right: 16px;
  top: 0;
  min-width: 40px;
}
nav ul.nav li a > .icon:before,
nav ul.nav li .nav-item > .icon:before {
  width: 24px;
  display: inline-block;
  text-align: center;
  opacity: 0.75;
}
nav ul.nav li a:hover,
nav ul.nav li .nav-item:hover,
nav ul.nav li a:focus,
nav ul.nav li .nav-item:focus {
  background-color: rgba(158, 158, 158, 0.2);
}
nav ul.nav li label {
  display: block;
}
nav ul.nav li label i {
  margin-left: -16px;
  margin-right: 48px;
}
nav ul.nav li.active {
  background-color: rgba(0, 0, 0, 0.05);
}
nav ul.nav li.active > a {
  background-color: transparent;
}
nav ul.nav li.active > a > .pull-right i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
nav ul.nav.nav-stacked a,
nav ul.nav.nav-stacked .nav-header {
  padding: 0 16px;
  line-height: 30px;
}
nav .nav-header {
  padding: 0 16px;
  line-height: 40px;
}
nav .nav-header:hover,
nav .nav-header:focus {
  background-color: transparent !important;
}
@media (min-width: 992px) {
  .folded {
    -webkit-transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 60;
  }
  .folded .nav li ul {
    display: none;
  }
  .folded .nav li a,
  .folded .nav li .nav-item {
    text-align: center;
  }
  .folded .nav li a span,
  .folded .nav li .nav-item span {
    display: none;
  }
  .folded .nav li a .up,
  .folded .nav li .nav-item .up {
    position: absolute;
    top: -8px;
    right: 4px;
  }
  .folded .nav li a .icon,
  .folded .nav li .nav-item .icon {
    float: none;
    line-height: 50px;
    margin-right: 0;
    min-width: 0;
  }
  .folded .nav li a label i,
  .folded .nav li .nav-item label i {
    margin-left: -38px;
    margin-right: 0;
  }
  .folded .navbar-brand {
    text-align: center;
  }
  .folded .hidden-folded {
    display: none;
  }
  .folded:hover,
  .folded:focus,
  .folded.active {
    width: 300px;
  }
  .folded:hover > *,
  .folded:focus > *,
  .folded.active > * {
    width: 300px;
  }
  .folded:hover .nav li ul,
  .folded:focus .nav li ul,
  .folded.active .nav li ul {
    display: inherit;
  }
  .folded:hover .nav li a,
  .folded:focus .nav li a,
  .folded.active .nav li a,
  .folded:hover .nav li .nav-item,
  .folded:focus .nav li .nav-item,
  .folded.active .nav li .nav-item {
    text-align: left;
  }
  .folded:hover .nav li a span,
  .folded:focus .nav li a span,
  .folded.active .nav li a span,
  .folded:hover .nav li .nav-item span,
  .folded:focus .nav li .nav-item span,
  .folded.active .nav li .nav-item span {
    display: inline;
  }
  .folded:hover .nav li a .up,
  .folded:focus .nav li a .up,
  .folded.active .nav li a .up,
  .folded:hover .nav li .nav-item .up,
  .folded:focus .nav li .nav-item .up,
  .folded.active .nav li .nav-item .up {
    position: static;
  }
  .folded:hover .nav li a .icon,
  .folded:focus .nav li a .icon,
  .folded.active .nav li a .icon,
  .folded:hover .nav li .nav-item .icon,
  .folded:focus .nav li .nav-item .icon,
  .folded.active .nav li .nav-item .icon {
    float: left;
    margin-right: 32px;
    line-height: inherit;
  }
  .folded:hover .nav li label i,
  .folded:focus .nav li label i,
  .folded.active .nav li label i {
    margin-right: 48px;
    margin-left: -16px;
  }
  .folded:hover .navbar-brand,
  .folded:focus .navbar-brand,
  .folded.active .navbar-brand {
    text-align: left;
  }
  .folded:hover .hidden-folded,
  .folded:focus .hidden-folded,
  .folded.active .hidden-folded {
    display: inherit;
  }
  .folded:hover .hidden-folded.inline,
  .folded:focus .hidden-folded.inline,
  .folded.active .hidden-folded.inline {
    display: inline-block;
  }
}
.nav-sub {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  transition: max-height 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}
.nav-sub .md-ripple-container {
  display: none;
  visibility: hidden;
}
.active > .nav-sub {
  max-height: 999px;
}
.active > .nav-sub .md-ripple-container {
  display: block;
  visibility: visible;
}
.i-16 {
  font-size: 16px;
  vertical-align: -15%;
}
.i-18 {
  font-size: 18px;
  vertical-align: -17%;
}
.i-20 {
  font-size: 20px;
  vertical-align: -19%;
}
.i-24 {
  font-size: 24px;
  vertical-align: -23%;
}
.ui-icon {
  display: inline-block;
  text-align: center;
  height: 1em;
  background: transparent !important;
}
.ui-icon:before {
  position: relative;
  z-index: 1;
}
.ui-icon:after {
  content: "";
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 2em;
  width: 2em;
  height: 2em;
  top: -1.5em;
  display: block;
  position: relative;
  z-index: 0;
  background-clip: padding-box;
}
.ui-icon.b-2x:after {
  border-width: 2px;
}
.ui-icon.b-3x:after {
  border-width: 3px;
}
.ui-icon.ui-icon-sm:after {
  width: 1.7em;
  height: 1.7em;
  top: -1.35em;
}
.ui-icon.bg-primary:after {
  background-color: #607d8b;
}
.ui-icon.bg-info:after {
  background-color: #2196f3;
}
.ui-icon.bg-success:after {
  background-color: #4caf50;
}
.ui-icon.bg-warning:after {
  background-color: #ffc107;
}
.ui-icon.bg-danger:after {
  background-color: #f44336;
}
.ui-icon.bg-accent:after {
  background-color: #7e57c2;
}
.ui-icon.bg-dark:after {
  background-color: #475069;
}
.ui-icon.bg-light:after {
  background-color: #eaebec;
}
.ui-icon.bg-white:after {
  background-color: #fff;
}
.item {
  position: relative;
}
.item .top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.item .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.item .center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.item-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.item-overlay.active,
.item:hover .item-overlay {
  display: block;
}
.list-icon i {
  -webkit-transition: font-size 0.2s;
  transition: font-size 0.2s;
  margin-right: 16px;
}
.list-icon div {
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*Charts*/
.jqstooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: solid 1px #000 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.easyPieChart {
  position: relative;
  text-align: center;
  display: table;
}
.easyPieChart > div {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  line-height: 1;
}
.easyPieChart > div img {
  margin-top: -4px;
}
.easyPieChart canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
#flotTip {
  padding: 4px 10px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: solid 1px #000 !important;
  z-index: 100;
  font-size: 12px;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.legendColorBox > div {
  border: none !important;
  margin: 5px;
}
.legendColorBox > div > div {
  border-radius: 10px;
}
.st-sort-ascent:before {
  content: '\25B2';
}
.st-sort-descent:before {
  content: '\25BC';
}
.st-selected td {
  background: #f0f9ec !important;
}
.ui-select-bootstrap > .ui-select-choices {
  overflow-y: scroll;
}
.panel .dataTables_wrapper {
  padding-top: 10px;
}
.panel .dataTables_wrapper > .row {
  margin: 0;
}
.panel .dataTables_wrapper > .row > .col-sm-12 {
  padding: 0;
}
.dataTables_wrapper.form-inline .form-control {
  width: auto;
}
.btn-info .text-info {
  color: #fff;
}
.ie .ie-show {
  display: block;
}
.waves-effect {
  -webkit-mask-image: -webkit-radial-gradient(circle, #ffffff 100%, #000000 100%);
}
.streamline {
  position: relative;
  border-color: #dddee0;
}
.streamline .sl-item:after,
.streamline:after {
  content: '';
  position: absolute;
  background-color: #fff;
  border-color: inherit;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin-left: -6px;
  bottom: 0;
  left: 0;
}
.sl-icon {
  position: absolute;
  left: -15px;
  z-index: 1;
}
.sl-icon > i {
  width: 30px;
  height: 30px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.sl-item {
  border-color: #dddee0;
  position: relative;
  padding-bottom: 1px;
}
.sl-item:before,
.sl-item:after {
  content: " ";
  display: table;
}
.sl-item:after {
  clear: both;
}
.sl-item:after {
  top: 5px;
  bottom: auto;
}
.sl-item.b-l {
  margin-left: -1px;
}
.sl-item.sl-item-md:after {
  top: 0;
  width: 30px;
  height: 30px;
  margin-left: -16px;
}
.sl-content {
  margin-left: 24px;
}
.ui-switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
  border-radius: 30px;
  background-color: #4caf50;
  margin: 0;
}
.ui-switch input {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-switch input:checked + i:before {
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 5px;
  border-width: 0;
  border-radius: 5px;
}
.ui-switch input:checked + i:after {
  margin-left: 15px;
}
.ui-switch i:before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 30px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ui-switch i:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 16px;
  top: 1px;
  bottom: 1px;
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  -webkit-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}
.ui-switch-md {
  width: 40px;
  height: 24px;
}
.ui-switch-md input:checked + i:after {
  margin-left: 17px;
}
.ui-switch-md i:after {
  width: 22px;
}
.ui-switch-lg {
  width: 50px;
  height: 30px;
}
.ui-switch-lg input:checked + i:after {
  margin-left: 21px;
}
.ui-switch-lg i:after {
  width: 28px;
}
.timeline {
  margin: 0;
  padding: 0;
}
.tl-item {
  display: block;
}
.tl-item:before,
.tl-item:after {
  content: " ";
  display: table;
}
.tl-item:after {
  clear: both;
}
.visible-left {
  display: none;
}
.tl-wrap {
  display: block;
  margin-left: 6em;
  padding: 15px 0 15px 20px;
  border-style: solid;
  border-color: #dddee0;
  border-width: 0 0 0 2px;
}
.tl-wrap:before,
.tl-wrap:after {
  content: " ";
  display: table;
}
.tl-wrap:after {
  clear: both;
}
.tl-wrap:before {
  position: relative;
  content: "";
  float: left;
  top: 15px;
  margin-left: -26px;
  width: 10px;
  height: 10px;
  border-color: inherit;
  border-width: 3px;
  border-radius: 50%;
  border-style: solid;
  background: #eaebec;
  box-shadow: 0 0 0 4px #efefef;
}
.tl-date {
  position: relative;
  top: 10px;
  float: left;
  margin-left: -7.5em;
  display: block;
  width: 4.5em;
  text-align: right;
}
.tl-content {
  display: inline-block;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tl-content.block {
  display: block;
  width: 100%;
}
.tl-content.panel {
  margin-bottom: 0;
}
.tl-header {
  display: block;
  width: 12em;
  text-align: center;
  margin-left: 1px;
}
.timeline-center .tl-item {
  margin-left: 50%;
}
.timeline-center .tl-item .tl-wrap {
  margin-left: -2px;
}
.timeline-center .tl-header {
  width: auto;
  margin-left: -1px;
}
.timeline-center .tl-left {
  margin-left: 0;
  margin-right: 50%;
}
.timeline-center .tl-left .hidden-left {
  display: none !important;
}
.timeline-center .tl-left .visible-left {
  display: inherit;
}
.timeline-center .tl-left .tl-wrap {
  float: right;
  margin-right: 0px;
  border-left-width: 0;
  border-right-width: 2px;
  padding-left: 0;
  padding-right: 20px;
}
.timeline-center .tl-left .tl-wrap:before {
  float: right;
  margin-left: 0;
  margin-right: -26px;
}
.timeline-center .tl-left .tl-date {
  float: right;
  margin-left: 0;
  margin-right: -8.5em;
  text-align: left;
}
.bg-white {
  background-color: #fff;
}
.bg-light {
  background-color: #eaebec;
}
.bg-light.lt {
  background-color: #f8f8f8 !important;
}
.bg-light .lt {
  background-color: #f8f8f8;
}
.bg-light.dk {
  background-color: #dbdee2 !important;
}
.bg-light .dk {
  background-color: #dbdee2;
}
.bg-light.bg {
  background-color: #eaebec !important;
}
.bg-light .bg {
  background-color: #eaebec;
}
.bg-dark {
  color: rgba(255, 255, 255, 0.87);
  background-color: #475069;
}
.bg-dark.lt {
  background-color: #565e73 !important;
}
.bg-dark .lt {
  background-color: #565e73;
}
.bg-dark.dk {
  background-color: #39435e !important;
}
.bg-dark .dk {
  background-color: #39435e;
}
.bg-dark.bg {
  background-color: #475069 !important;
}
.bg-dark .bg {
  background-color: #475069;
}
.bg-primary {
  color: rgba(255, 255, 255, 0.87);
  background-color: #607d8b;
}
.bg-primary.lt {
  background-color: #728893 !important;
}
.bg-primary .lt {
  background-color: #728893;
}
.bg-primary.dk {
  background-color: #507181 !important;
}
.bg-primary .dk {
  background-color: #507181;
}
.bg-primary.bg {
  background-color: #607d8b !important;
}
.bg-primary .bg {
  background-color: #607d8b;
}
.bg-success {
  color: rgba(255, 255, 255, 0.87);
  background-color: #4caf50;
}
.bg-success.lt {
  background-color: #62b265 !important;
}
.bg-success .lt {
  background-color: #62b265;
}
.bg-success.dk {
  background-color: #3fa343 !important;
}
.bg-success .dk {
  background-color: #3fa343;
}
.bg-success.bg {
  background-color: #4caf50 !important;
}
.bg-success .bg {
  background-color: #4caf50;
}
.bg-info {
  color: rgba(255, 255, 255, 0.87);
  background-color: #2196f3;
}
.bg-info.lt {
  background-color: #3ea1ef !important;
}
.bg-info .lt {
  background-color: #3ea1ef;
}
.bg-info.dk {
  background-color: #078bf4 !important;
}
.bg-info .dk {
  background-color: #078bf4;
}
.bg-info.bg {
  background-color: #2196f3 !important;
}
.bg-info .bg {
  background-color: #2196f3;
}
.bg-warning {
  color: rgba(255, 255, 255, 0.87);
  background-color: #ffc107;
}
.bg-warning.lt {
  background-color: #f9c526 !important;
}
.bg-warning .lt {
  background-color: #f9c526;
}
.bg-warning.dk {
  background-color: #ecb100 !important;
}
.bg-warning .dk {
  background-color: #ecb100;
}
.bg-warning.bg {
  background-color: #ffc107 !important;
}
.bg-warning .bg {
  background-color: #ffc107;
}
.bg-danger {
  color: rgba(255, 255, 255, 0.87);
  background-color: #f44336;
}
.bg-danger.lt {
  background-color: #f15e53 !important;
}
.bg-danger .lt {
  background-color: #f15e53;
}
.bg-danger.dk {
  background-color: #f92718 !important;
}
.bg-danger .dk {
  background-color: #f92718;
}
.bg-danger.bg {
  background-color: #f44336 !important;
}
.bg-danger .bg {
  background-color: #f44336;
}
.bg-accent {
  color: rgba(255, 255, 255, 0.87);
  background-color: #7e57c2;
}
.bg-accent.lt {
  background-color: #8e6fc4 !important;
}
.bg-accent .lt {
  background-color: #8e6fc4;
}
.bg-accent.dk {
  background-color: #6e3ec2 !important;
}
.bg-accent .dk {
  background-color: #6e3ec2;
}
.bg-accent.bg {
  background-color: #7e57c2 !important;
}
.bg-accent .bg {
  background-color: #7e57c2;
}
a.bg-primary:hover {
  background-color: #566f7c;
}
a.text-primary:hover {
  color: #566f7c;
}
.text-primary {
  color: #607d8b;
}
.text-primary-lt {
  color: #6b8a99;
}
.text-primary-dk {
  color: #566f7c;
}
a.bg-info:hover {
  background-color: #0d8aee;
}
a.text-info:hover {
  color: #0d8aee;
}
.text-info {
  color: #2196f3;
}
.text-info-lt {
  color: #39a1f4;
}
.text-info-dk {
  color: #0d8aee;
}
a.bg-success:hover {
  background-color: #449d48;
}
a.text-success:hover {
  color: #449d48;
}
.text-success {
  color: #4caf50;
}
.text-success-lt {
  color: #5cb860;
}
.text-success-dk {
  color: #449d48;
}
a.bg-warning:hover {
  background-color: #ecb100;
}
a.text-warning:hover {
  color: #ecb100;
}
.text-warning {
  color: #ffc107;
}
.text-warning-lt {
  color: #ffc721;
}
.text-warning-dk {
  color: #ecb100;
}
a.bg-danger:hover {
  background-color: #f32c1e;
}
a.text-danger:hover {
  color: #f32c1e;
}
.text-danger {
  color: #f44336;
}
.text-danger-lt {
  color: #f55a4e;
}
.text-danger-dk {
  color: #f32c1e;
}
a.bg-accent:hover {
  background-color: #7044bb;
}
a.text-accent:hover {
  color: #7044bb;
}
.text-accent {
  color: #7e57c2;
}
.text-accent-lt {
  color: #8c6ac9;
}
.text-accent-dk {
  color: #7044bb;
}
a.bg-dark:hover {
  background-color: #3d445a;
}
a.text-dark:hover {
  color: #3d445a;
}
.text-dark {
  color: #475069;
}
.text-dark-lt {
  color: #515c78;
}
.text-dark-dk {
  color: #3d445a;
}
.text-white {
  color: #ffffff;
}
.text-white-lt {
  color: rgba(255, 255, 255, 0.54);
}
.text-white-dk {
  color: rgba(255, 255, 255, 0.87);
}
.text-black {
  color: #000000;
}
.text-black-lt {
  color: rgba(0, 0, 0, 0.54);
}
.text-black-dk {
  color: rgba(0, 0, 0, 0.87);
}
.text-muted {
  color: inherit;
  opacity: 0.75;
}
.text-muted-dk {
  color: inherit;
  opacity: 0.5;
}
.text-muted-lt {
  color: inherit;
  opacity: 0.25;
}
.text-color {
  color: rgba(0, 0, 0, 0.87);
}
.bg-big:before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: inherit;
  border: inherit;
}
.bg-white-overlay {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-black-overlay {
  background-color: rgba(32, 43, 54, 0.8);
}
.no-bg {
  background-color: transparent;
  color: inherit;
}
@media (min-width: 768px) {
  .bg-auto:before {
    content: "";
    position: absolute;
    width: inherit;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: inherit;
    border: inherit;
  }
  .bg-auto.b-l:before {
    margin-left: -1px;
  }
  .bg-auto.b-r:before {
    margin-right: -1px;
  }
}
.pos-rlt {
  position: relative;
  z-index: 1;
}
.pos-abt {
  position: absolute;
}
.pos-fix {
  position: fixed !important;
}
.pos-stc {
  position: static !important;
}
.block {
  display: block;
}
.block.hide {
  display: none;
}
.pull-none {
  float: none;
}
.inline {
  display: inline-block;
}
.inline-16 {
  width: 16px;
  height: 16px;
}
.inline-24 {
  width: 24px;
  height: 24px;
}
.none {
  display: none;
}
.active > .inline,
.active > .auto .inline {
  display: none;
}
.active > .none,
.active > .auto .none {
  display: inline-block;
}
.line {
  height: 1px;
  margin: 10px 0;
  font-size: 0;
  overflow: hidden;
}
.line-dashed {
  border-style: dashed !important;
  background-color: transparent;
  border-width: 0;
}
.pointer {
  cursor: pointer;
}
.rounded,
.circle {
  border-radius: 500px;
}
.clear {
  display: block;
  overflow: hidden;
}
.no-border {
  border-color: transparent;
  border-width: 0;
}
.no-borders {
  border-width: 0 !important;
}
.b {
  border-color: rgba(110, 115, 120, 0.15) !important;
}
.b-a {
  border: 1px solid #dddee0;
}
.b-t {
  border-top: 1px solid #dddee0;
}
.b-r {
  border-right: 1px solid #dddee0;
}
.b-b {
  border-bottom: 1px solid #dddee0;
}
.b-l {
  border-left: 1px solid #dddee0;
}
.b-2x {
  border-width: 2px;
}
.b-3x {
  border-width: 3px;
}
.b-4x {
  border-width: 4px;
}
.b-5x {
  border-width: 5px;
}
.b-t-2x {
  border-top-width: 2px !important;
}
.b-t-3x {
  border-top-width: 3px !important;
}
.b-t-4x {
  border-top-width: 4px !important;
}
.b-t-5x {
  border-top-width: 5px !important;
}
.b-r-2x {
  border-right-width: 2px !important;
}
.b-r-3x {
  border-right-width: 3px !important;
}
.b-r-4x {
  border-right-width: 4px !important;
}
.b-r-5x {
  border-right-width: 5px !important;
}
.b-b-2x {
  border-bottom-width: 2px !important;
}
.b-b-3x {
  border-bottom-width: 3px !important;
}
.b-b-4x {
  border-bottom-width: 4px !important;
}
.b-b-5x {
  border-bottom-width: 5px !important;
}
.b-l-2x {
  border-left-width: 2px !important;
}
.b-l-3x {
  border-left-width: 3px !important;
}
.b-l-4x {
  border-left-width: 4px !important;
}
.b-l-5x {
  border-left-width: 5px !important;
}
.b-light {
  border-color: #eaebec;
}
.b-white {
  border-color: #fff;
}
.b-primary {
  border-color: #607d8b;
}
.b-t-primary {
  border-top-color: #607d8b;
}
.b-r-primary {
  border-right-color: #607d8b;
}
.b-b-primary {
  border-bottom-color: #607d8b;
}
.b-l-primary {
  border-left-color: #607d8b;
}
.b-info {
  border-color: #2196f3;
}
.b-t-info {
  border-top-color: #2196f3;
}
.b-r-info {
  border-right-color: #2196f3;
}
.b-b-info {
  border-bottom-color: #2196f3;
}
.b-l-info {
  border-left-color: #2196f3;
}
.b-success {
  border-color: #4caf50;
}
.b-t-success {
  border-top-color: #4caf50;
}
.b-r-success {
  border-right-color: #4caf50;
}
.b-b-success {
  border-bottom-color: #4caf50;
}
.b-l-success {
  border-left-color: #4caf50;
}
.b-warning {
  border-color: #ffc107;
}
.b-t-warning {
  border-top-color: #ffc107;
}
.b-r-warning {
  border-right-color: #ffc107;
}
.b-b-warning {
  border-bottom-color: #ffc107;
}
.b-l-warning {
  border-left-color: #ffc107;
}
.b-danger {
  border-color: #f44336;
}
.b-t-danger {
  border-top-color: #f44336;
}
.b-r-danger {
  border-right-color: #f44336;
}
.b-b-danger {
  border-bottom-color: #f44336;
}
.b-l-danger {
  border-left-color: #f44336;
}
.b-accent {
  border-color: #7e57c2;
}
.b-t-accent {
  border-top-color: #7e57c2;
}
.b-r-accent {
  border-right-color: #7e57c2;
}
.b-b-accent {
  border-bottom-color: #7e57c2;
}
.b-l-accent {
  border-left-color: #7e57c2;
}
.b-dark {
  border-color: #475069;
}
.b-t-dark {
  border-top-color: #475069;
}
.b-r-dark {
  border-right-color: #475069;
}
.b-b-dark {
  border-bottom-color: #475069;
}
.b-l-dark {
  border-left-color: #475069;
}
.no-b-t {
  border-top-width: 0;
}
.no-b-r {
  border-right-width: 0;
}
.no-b-b {
  border-bottom-width: 0;
}
.no-b-l {
  border-left-width: 0;
}
.b-dashed {
  border-style: dashed !important;
}
@media (max-width: 991px) {
  .no-border-sm {
    border-width: 0;
  }
}
@media (max-width: 767px) {
  .no-border-xs {
    border-width: 0;
  }
}
.hover-action {
  display: none;
}
.hover-rotate {
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  transition: all 0.2s ease-in-out 0.1s;
}
.hover-anchor:hover .hover-action,
.hover-anchor:focus .hover-action,
.hover-anchor:active .hover-action {
  display: inherit;
}
.hover-anchor:hover .hover-rotate,
.hover-anchor:focus .hover-rotate,
.hover-anchor:active .hover-rotate {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.no-margin {
  margin: 0;
}
.m {
  margin: 16px;
}
.m-xs {
  margin: 4px;
}
.m-sm {
  margin: 8px;
}
.m-md {
  margin: 24px;
}
.m-lg {
  margin: 32px;
}
.m-v-xs {
  margin-top: 4px;
  margin-bottom: 4px;
}
.m-v-sm {
  margin-top: 8px;
  margin-bottom: 8px;
}
.m-v {
  margin-top: 16px;
  margin-bottom: 16px;
}
.m-v-md {
  margin-top: 24px;
  margin-bottom: 24px;
}
.m-v-lg {
  margin-top: 32px;
  margin-bottom: 32px;
}
.m-h-xs {
  margin-left: 4px;
  margin-right: 4px;
}
.m-h-sm {
  margin-left: 8px;
  margin-right: 8px;
}
.m-h {
  margin-left: 16px;
  margin-right: 16px;
}
.m-h-md {
  margin-left: 24px;
  margin-right: 24px;
}
.m-h-lg {
  margin-left: 32px;
  margin-right: 32px;
}
.m-t {
  margin-top: 16px;
}
.m-t-xs {
  margin-top: 4px;
}
.m-t-sm {
  margin-top: 8px;
}
.m-t-md {
  margin-top: 24px;
}
.m-t-lg {
  margin-top: 32px;
}
.m-r {
  margin-right: 16px;
}
.m-r-xs {
  margin-right: 4px;
}
.m-r-sm {
  margin-right: 8px;
}
.m-r-md {
  margin-right: 24px;
}
.m-r-lg {
  margin-right: 32px;
}
.m-b {
  margin-bottom: 16px;
}
.m-b-xs {
  margin-bottom: 4px;
}
.m-b-sm {
  margin-bottom: 8px;
}
.m-b-md {
  margin-bottom: 24px;
}
.m-b-lg {
  margin-bottom: 32px;
}
.m-l {
  margin-left: 16px;
}
.m-l-xs {
  margin-left: 4px;
}
.m-l-sm {
  margin-left: 8px;
}
.m-l-md {
  margin-left: 24px;
}
.m-l-lg {
  margin-left: 32px;
}
.m-n {
  margin: -16px;
}
.m-h-n {
  margin-left: -16px;
  margin-right: -16px;
}
.m-v-n {
  margin-top: -16px;
  margin-bottom: -16px;
}
.m-l-n {
  margin-left: -16px;
}
.m-r-n {
  margin-right: -16px;
}
.m-t-n {
  margin-top: -16px;
}
.m-b-n {
  margin-bottom: -16px;
}
.no-padding {
  padding: 0 !important;
}
.no-padding-h {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-padding-v {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.p-xs {
  padding: 4px;
}
.p-sm {
  padding: 8px;
}
.p {
  padding: 16px;
}
.p-md {
  padding: 24px;
}
.p-lg {
  padding: 32px;
}
.p-h {
  padding-left: 16px;
  padding-right: 16px;
}
.p-h-xs {
  padding-left: 4px;
  padding-right: 4px;
}
.p-h-sm {
  padding-left: 8px;
  padding-right: 8px;
}
.p-h-md {
  padding-left: 24px;
  padding-right: 24px;
}
.p-h-lg {
  padding-left: 32px;
  padding-right: 32px;
}
.p-v {
  padding-top: 16px;
  padding-bottom: 16px;
}
.p-v-xs {
  padding-top: 4px;
  padding-bottom: 4px;
}
.p-v-sm {
  padding-top: 8px;
  padding-bottom: 8px;
}
.p-v-md {
  padding-top: 24px;
  padding-bottom: 24px;
}
.p-v-lg {
  padding-top: 32px;
  padding-bottom: 32px;
}
.padding {
  padding: 32px 32px;
}
.padding-out {
  margin: -32px -32px;
}
@media (max-width: 992px) {
  .padding {
    padding: 16px 16px;
  }
  .padding-out {
    margin: -16px -16px;
  }
}
@media (max-width: 767px) {
  .padding {
    padding: 8px 8px;
  }
  .padding-out {
    margin: -8px -8px;
  }
}
.no-radius {
  border-radius: 0 !important;
}
.no-r-t {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.no-r-r {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.no-r-b {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.no-r-l {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.r {
  border-radius: 3px;
}
.r-t {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.r-r {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.r-b {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.r-l {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.r-2x {
  border-radius: 6px;
}
.r-3x {
  border-radius: 9px;
}
.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollable.hover {
  overflow-y: hidden !important;
}
.scrollable.hover > * {
  margin-top: -1px;
}
.scrollable.hover:hover {
  overflow: visible !important;
  overflow-y: auto !important;
}
.smart .scrollable {
  overflow-y: auto !important;
}
.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.scroll-y {
  overflow-y: auto;
}
.scroll-x {
  overflow-x: auto;
}
.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.box-shadow {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.box-shadow-md {
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
}
.box-shadow-lg {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}
.box-shadow-inset {
  -webkit-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
}
.text-shadow {
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}
.l-h {
  line-height: 1.42857143;
}
.l-h-1x {
  line-height: 1;
}
.l-h-2x {
  line-height: 2em;
}
.l-s-1x {
  letter-spacing: 1;
}
.l-s-2x {
  letter-spacing: 2;
}
.l-s-3x {
  letter-spacing: 3;
}
.h-1x {
  height: 20px;
}
.h-2x {
  height: 40px;
}
.h-3x {
  height: 60px;
}
.h-4x {
  height: 80px;
}
.font-normal {
  font-weight: normal;
}
.font-thin {
  font-weight: 300;
}
.font-bold {
  font-weight: 700;
}
.text-4x {
  font-size: 4em;
}
.text-3x {
  font-size: 3em;
}
.text-2x {
  font-size: 2em;
}
.text-xl {
  font-size: 32px;
}
.text-lg {
  font-size: 24px;
}
.text-md {
  font-size: 16px;
}
.text-base {
  font-size: 14px;
}
.text-sm {
  font-size: 13px;
}
.text-xs {
  font-size: 12px;
}
.text-xxs {
  font-size: 10px;
}
.text-u-c {
  text-transform: uppercase;
}
.text-l-t {
  text-decoration: line-through;
}
.text-u-l {
  text-decoration: underline;
}
.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-fade {
  position: relative;
  overflow: hidden;
}
.text-fade:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 50%);
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .text-center-sm {
    text-align: center;
  }
  .text-left-sm {
    text-align: left;
  }
  .text-right-sm {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
}
.w-16 {
  width: 16px;
  height: 16px;
}
.w-20 {
  width: 20px;
  height: 20px;
}
.w-24 {
  width: 24px;
  height: 24px;
}
.w-32 {
  width: 32px;
  height: 32px;
}
.w-40 {
  width: 40px;
  height: 40px;
}
.w-48 {
  width: 48px;
  height: 48px;
}
.w-56 {
  width: 56px;
  height: 56px;
}
.w-64 {
  width: 64px;
  height: 64px;
}
.w-xxl {
  width: 60px;
}
.w-xs {
  width: 90px;
}
.w-sm {
  width: 120px;
}
.w {
  width: 180px;
}
.w-md {
  width: 200px;
}
.w-lg {
  width: 240px;
}
.w-xl {
  width: 280px;
}
.w-xxl {
  width: 320px;
}
.w-full {
  width: 100%;
}
.w-auto {
  width: auto;
}
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
@media (max-width: 991px) {
  .w-auto-sm {
    width: auto;
  }
  .w-full-sm {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .w-auto-xs {
    width: auto;
  }
  .w-full-xs {
    width: 100%;
  }
}
.primary-text {
  color: #607d8b !important;
}
.md-list-item:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.dropdown ul li a:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.dropdown ul li.active {
  background-color: rgba(158, 158, 158, 0.2) !important;
}
.treeViewOfUL div li a {
  padding: 5px 10px;
}
.treeViewOfUL div li a:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.treeViewOfUL div li.active {
  background-color: rgba(158, 158, 158, 0.2) !important;
}
.customDropdown {
  padding: 0px;
}
.customDropdown li a {
  padding: 8px 0px 8px 15px;
  display: block;
}
.customDropdown li a:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.customDropdown li.active {
  background-color: rgba(158, 158, 158, 0.2) !important;
}
.btn-icon-sm {
  width: 25px;
  height: 25px;
  line-height: 25px;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #607d8b;
  border-color: #607d8b;
}
.dropdown-menu .disabled > a,
.dropdown-menu .disabled > a:hover,
.dropdown-menu .disabled > a:focus {
  color: #b9b9b9;
}
.dropdown-menu .disabled > a:hover,
.dropdown-menu .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: default;
}
.modal-header .close {
  margin-top: 2px;
}
.md-list .active {
  border-right: 2px #E91E63 solid;
}
.text-dim {
  color: #ccc !important;
  vertical-align: middle;
}
.login-form {
  width: 350px !important;
}
.b-b-0 {
  margin-bottom: 0px !important;
}
#loading-bar-spinner .spinner-icon {
  border-top-color: #EB883F;
  border-left-color: #EB883F;
  display: none;
}
#loading-bar .peg {
  -moz-box-shadow: #EB883F 1px 0 6px 1px;
  -ms-box-shadow: #EB883F 1px 0 6px 1px;
  -webkit-box-shadow: #EB883F 1px 0 6px 1px;
  box-shadow: #EB883F 1px 0 6px 1px;
}
#loading-bar .bar {
  background: #EB883F;
}
.action-div {
  font-size: 18px;
}
.action-div i {
  color: #404040;
  line-height: 0;
}
.action-div i:hover {
  color: #000;
}
#queryCategorySettingModal .list-with-add {
  height: calc(100% - 175px) !important;
  height: -webkit-calc(100% - 175px) !important;
  height: -moz-calc(100% - 175px) !important;
}
#hubSettingModal .list-with-add {
  height: calc(100% - 175px) !important;
  height: -webkit-calc(100% - 175px) !important;
  height: -moz-calc(100% - 175px) !important;
}
#serviceSettingModal .list-with-add {
  height: calc(100% - 175px) !important;
  height: -webkit-calc(100% - 175px) !important;
  height: -moz-calc(100% - 175px) !important;
}
#operatorSettingModal .list-with-add {
  height: calc(100% - 175px) !important;
  height: -webkit-calc(100% - 175px) !important;
  height: -moz-calc(100% - 175px) !important;
}
.editable-click {
  text-decoration: none;
  color: inherit;
  border-bottom: none;
}
.md-list-item-edit:hover {
  background: #f0f0f0 !important;
}
.backdrop {
  height: 100%;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7 !important;
  background-color: #475069;
}
.pagination > li > a,
.pagination > li > span {
  color: #607d8b;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #607d8b;
  border-color: #607d8b;
}
.seatContainer .btn-default.active,
.seatContainer .btn-default.focus,
.seatContainer .btn-default:active,
.seatContainer .btn-default:focus,
.seatContainer .btn-default:hover,
.seatContainer .open > .dropdown-toggle.btn-default {
  background-color: inherit;
  outline: none;
}
.seatBtn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  font-weight: 500;
  border-radius: 3px;
  outline: 0 !important;
  box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
  line-height: 1.5;
  border-color: #dddee0;
}
.screenRowBtn {
  padding: 0px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  font-weight: 500;
  border-radius: 3px;
  outline: 0 !important;
  box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
  line-height: 1.5;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #607d8b;
  border-color: #607d8b;
  color: #fff;
}
.daterangepicker td.in-range {
  background: #ebf4f8;
}
/*.daterangepicker .ranges {
  display: none !important;
}*/
.show-panel .md-tabs-content {
  height: calc(100% - 48px);
}
.show-panel md-tabs {
  height: 100%;
}
md-radio-button.md-default-theme .md-on {
  background-color: #607d8b;
}
md-radio-button.md-default-theme.md-checked .md-off {
  border-color: #607d8b;
}
.table > tbody + tbody {
  border-top: 0px;
}
.easyRecharge-panel .md-tabs-content {
  height: calc(100% - 48px);
  overflow: auto;
}
.easyRecharge-panel md-tabs {
  height: 100%;
}
.postpaid-panel .md-tabs-content {
  height: calc(100% - 48px);
  overflow: auto;
}
.postpaid-panel md-tabs {
  height: 100%;
}
.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  padding: 7px 15px;
}
.table > thead > tr > th {
  padding: 7px 15px;
}
.panel-heading {
  padding: 11px !important;
  padding-left: 15px!important;
}
.panel-heading .title {
  font-size: 18px!important;
  font-weight: bold;
}
.filter-label {
  color: #607d8b;
  opacity: 1;
  top: 0 !important;
  font-size: 0.85em !important;
}
.p-t-15 {
  padding-top: 15px;
}
.fullWidth {
  width: 100%;
}
.record-count {
  padding: 8px 15px;
  color: #607d8b;
  font-size: 16px;
}
.record-list {
  height: calc(100% - 44px);
  margin-top: 1px;
}
.daterangepicker.openscenter .ranges {
  margin-right: 25px;
}
md-checkbox .md-label {
  border: none;
}
md-checkbox.md-default-theme.md-checked .md-icon {
  background-color: #607d8b;
}
.alertify-test .modal-backdrop.in {
  opacity: 0.4;
}
.password-confirmation-test .modal-backdrop.in {
  opacity: 0.4;
}
.bg-light-grey {
  background: #F0F0F0;
}
.md-autocomplete-suggestions {
  z-index: 60000000 !important;
}
h3,
.h3 {
  font-size: 16px;
}
.md-tab.md-disabled {
  opacity: 1 !important;
  color: #000 !important;
  font-size: 16px;
  font-weight: bold !important;
}
md-autocomplete[md-floating-label] {
  margin-bottom: 0px!important;
  padding-bottom: 0px;
  height: 54px;
}
md-autocomplete[md-floating-label] md-autocomplete-wrap {
  height: 54px;
}
md-input-container:not(.md-input-invalid).md-input-focused label {
  color: #607d8b;
  opacity: 1;
  top: 0 !important;
}
md-input-container:not(.md-input-invalid).md-input-has-value label {
  color: #607d8b;
  opacity: 1;
  top: 0 !important;
}
md-chips .md-chips.md-focused {
  box-shadow: 0 2px #607d8b !important;
}
md-input-container:not(.md-input-invalid).md-input-focused .md-input {
  border-color: #607d8b;
}
.p-0 {
  padding: 0px;
}
#addNewMainMovie .modal-dialog .modal-body .dropdown-list .multiselect-container.dropdown-menu,
#viewMainMovieDetailModal .modal-dialog .modal-body .dropdown-list .multiselect-container.dropdown-menu {
  height: 300px;
  overflow: auto;
}
#addNewMainMovie .modal-dialog .modal-body .dropdown-list .btn,
#viewMainMovieDetailModal .modal-dialog .modal-body .dropdown-list .btn,
#addNewMainMovie .modal-dialog .modal-body .dropdown-list .btn-default,
#viewMainMovieDetailModal .modal-dialog .modal-body .dropdown-list .btn-default,
#addNewMainMovie .modal-dialog .modal-body .dropdown-list .btn:active,
#viewMainMovieDetailModal .modal-dialog .modal-body .dropdown-list .btn:active,
#addNewMainMovie .modal-dialog .modal-body .dropdown-list .btn:hover,
#viewMainMovieDetailModal .modal-dialog .modal-body .dropdown-list .btn:hover,
#addNewMainMovie .modal-dialog .modal-body .dropdown-list .btn:focus,
#viewMainMovieDetailModal .modal-dialog .modal-body .dropdown-list .btn:focus,
#addNewMainMovie .modal-dialog .modal-body .dropdown-list .btn-default:active,
#viewMainMovieDetailModal .modal-dialog .modal-body .dropdown-list .btn-default:active,
#addNewMainMovie .modal-dialog .modal-body .dropdown-list .btn-default:hover,
#viewMainMovieDetailModal .modal-dialog .modal-body .dropdown-list .btn-default:hover,
#addNewMainMovie .modal-dialog .modal-body .dropdown-list .btn-default:focus,
#viewMainMovieDetailModal .modal-dialog .modal-body .dropdown-list .btn-default:focus {
  border: 0px;
  box-shadow: 0 0px 0px rgba(90, 90, 90, 0.1);
  padding-left: 0px;
  background-color: rgba(255, 255, 255, 0) !important;
}
.dropdown-list .multiselect-container.dropdown-menu li a {
  color: inherit;
  padding: 3px 5px;
}
.dropdown-list .multiselect-container.dropdown-menu .multiselect-item.filter .input-group .input-group-btn {
  display: none;
}
.dropdown-list .multiselect-container.dropdown-menu .multiselect-item.filter .input-group .multiselect-search {
  padding: 0;
  float: none;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid #757575;
}
.dropdown-list .multiselect-container.dropdown-menu .multiselect-item.filter .input-group .input-group-addon {
  background-color: #fff;
  border: 0;
  color: #607D8B;
  border-bottom: 1px solid #757575;
  border-radius: 0;
}
.p-b-24 {
  padding-bottom: 24px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.autocomplete-customer-template .title {
  display: block;
  width: 100%;
}
.autocomplete-customer-template .title .name {
  font-size: 16px;
  float: left;
}
.autocomplete-customer-template .title .userid {
  float: right;
  text-align: right;
  color: #666;
}
.autocomplete-customer-template .contact-data {
  width: 100%;
  display: block;
}
.autocomplete-customer-template .contact-data .email {
  width: calc(100% - 100px);
  float: right;
  text-align: right;
  color: #666;
}
.autocomplete-customer-template .contact-data .mobile {
  width: 100px;
  float: left;
  color: #666;
}
.autocomplete-customer-template li {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding: 3px 10px;
  line-height: 28px;
  white-space: normal;
}
.autocomplete-customer-template li:last-child {
  border-bottom-width: 0;
}
md-tab-content {
  overflow: hidden;
}
md-autocomplete {
  background: #ffffff;
}
.p-l-r-5 {
  padding-left: 5px ;
  padding-right: 5px;
}
#addNewEvent form .md-form-group {
  padding-bottom: 5px;
}
.p-lr-0 {
  padding-left: 0;
  padding-right: 0;
}
#editShowModal .modal-body .selected-info {
  background-color: #EFEFEF;
}
#editShowModal .modal-body .selected-info .head-title {
  margin: 10px 10px 10px 0px;
}
#editShowModal .modal-body .selected-info .head-title-underline {
  margin-top: 10px;
  margin-bottom: 10px;
}
#addRemarkAfterEditShowModal .modal-dialog.modal-small {
  width: 45px;
}
.display-inline {
  display: inline;
}
#addEditEventChargeModal .md-form-group {
  padding-top: 20px;
  padding-bottom: 5px;
}
#addEditEventGalleryModal form md-chips md-chips-wrap {
  margin-bottom: 5px;
}
#theatresPanel .panel-heading {
  font-size: 20px;
}
#theatresPanel .panel-content {
  height: calc(100% - 107px);
}
#theaterPartnerPanel .panel-heading {
  font-size: 20px;
}
#theaterPartnerPanel .list-with-add {
  height: calc(100% - 193px);
}
#theaterPartnerPanel .list-height {
  height: calc(100% - 59px);
}
#theaterDetailModal .detailViewLabel {
  font-size: 13px !important;
  color: #999 !important;
  font-weight: 100;
  /*margin-right: 10px;*/
}
#theaterDetailModal .detailViewField {
  display: inline-block;
}
#theaterDetailModal .inline-edit {
  height: 49px;
}
#theaterDetailModal .main-title-block {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-top: 8px;
}
#theaterDetailModal .main-block {
  padding: 7px 15px 15px 15px;
}
#theaterDetailModal .margin-top {
  margin-top: 6px;
}
#theaterDetailModal .contact-block {
  height: calc(100% - 130px) !important;
}
#theaterDetailModal .contact-list {
  height: calc(100% - 70px) !important;
}
#theaterDetailModal .dropdown-title {
  margin-top: 6px;
  color: #999 !important;
}
#theaterDetailModal .dropdown-field {
  display: inline-block;
}
#theaterPartnerDetailContent .md-tabs-content {
  height: calc(100% - 48px);
  height: moz-calc(100% - 48px);
  height: webkit-calc(100% - 48px);
}
.commonTheaterContent {
  height: calc(100% - 43px);
  height: moz-calc(100% - 43px);
  height: webkit-calc(100% - 43px);
}
.theaterPartnerContent {
  height: calc(100% - 73px);
  height: moz-calc(100% - 73px);
  height: webkit-calc(100% - 73px);
}
.theaterListWithPage {
  height: calc(100% - 45px) !important;
}
/**
 * Created by anand.patel on 5/6/2015.
 */
#userViewPage .customerList small {
  font-size: 12px;
}
.folded:hover .nav li a .expand-caret-icon .icon, .folded:focus .nav li a .expand-caret-icon .icon, .folded.active .nav li a .expand-caret-icon .icon, .folded:hover .nav li .nav-item .expand-caret-icon .icon, .folded:focus .nav li .nav-item .expand-caret-icon .icon, .folded.active .nav li .nav-item .expand-caret-icon .icon{margin-right: 0px;}