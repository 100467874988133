body {
    font-family: "Lato-Regular", helvetica, arial, sans-serif;
    /*font-size: 14px;*/
    /*line-height: 100%;*/
    color: #393939;
    /*border-top: solid 5px #111;*/
}

body .navbar-default {
    z-index: 1000;
}

h1, h2, h3, h4, h5 {
    font-family: "Lato-Regular", helvetica, arial, sans-serif;
}

.main-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 60px;
    min-height: 100%;
    width: 60px;
    z-index: 10;
    background: #222D32;
    transition: transform .3s cubic-bezier(.32, 1.25, .375, 1.15);
}

.main-sidebar .sidebar-menu {
    list-style: none;
    padding: 10px;
    font-size: 30px;
    text-align: center;
}

.main-sidebar .sidebar-menu li {
    margin: 5px 0px;
}

.main-sidebar .sidebar-menu li a {
    color: #fff;
}

.dropdown-menu {
    padding: 0px !important;
}

.dropdown-menu .user-header {
    height: 175px;
    padding: 10px;
    text-align: center;
    background-color: #2A5956;
    width: 300px;
}

.dropdown-menu .user-header img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
    vertical-align: middle;
    border-radius: 50%;
}

.dropdown-menu .user-header p {
    z-index: 5;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    font-size: 17px;
    margin-top: 10px;
}

.notifications-menu .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
    width: 280px;
    padding: 0 0 0 0 !important;
    margin: 0 !important;
    top: 100%;
}

.notifications-menu .dropdown-menu .header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #ffffff;
    padding: 7px 10px;
    border-bottom: 1px solid #f4f4f4;
    color: #444444;
    font-size: 14px;
}

.notifications-menu .dropdown-menu .menu {
    color: #444444;
    padding: 0px;
}

.notifications-menu .dropdown-menu .menu li {
    padding: 10px
}

.notifications-menu .dropdown-menu .menu li a {
    color: #444;
}

.modal {
    text-align: center;
}

.modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 90%;
}

.modal_header {
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

.modal_header h4 {
    height: 25px;
}

.modal-body {
    padding: 0px !important;
}

.bottomView, .detailView .row {
    margin: 0px;
    background-color: #F0F0F0;
}

.bottomView .modal-header {
    background-color: #ffffff !important;
}

.border {
    border: 1px solid #dddddd;
}

.bottomBorder {
    border-bottom: 1px solid #dddddd;
}

.topBorder {
    border-top: 1px solid #dddddd;
}

.rightBorder {
    border-right: 1px solid #dddddd;
}

.leftBorder {
    border-left: 1px solid #dddddd;
}

.padd15 {
    padding: 15px;
}

.padd5 {
    padding: 5px;
}

.padd10 {
    padding: 10px;
}

.paddTop5 {
    padding-top: 5px !important;
}

.transactionDetail .actionBar {
    padding: 5px;
    height: 52px;
}

.transactionDetail .actionBar button {
    padding: 5px 10px;
    margin: 5px;
}

.paddTop15 {
    padding-top: 15px !important;
}

.transactionDetail .mainBar {
    padding: 10px 0px 10px 0px;
    font-size: 15px;
}

.noRecord {
    vertical-align: middle;
    text-align: center;
    height: 100%;
    width: 100%;
    display: table;
    font-size: 16px;
    color: #989898;
}

.noRecord > div {
    display: table-cell;
    vertical-align: middle
}

.paddLeft15 {
    padding-left: 15px !important;
}

.paddRight15 {
    padding-right: 15px !important;
}

.margin10 {
    margin: 5px 5px 5px 5px;
}

.marginTop5 {
    margin-top: 5px !important;
}

.marginTop15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px;
}

.marginBottom5 {
    margin-bottom: 5px !important;
}

.marginBottom15 {
    margin-bottom: 15px !important;
}

.marginBottom50 {
    margin-bottom: 50px !important;
}

.marginBottom45 {
    margin-bottom: 45px !important;
}

.marginRight10 {
    margin-right: 10px !important;
}

.marginRight20 {
    margin-right: 20px !important;
}

.paddBottom0 {
    padding-bottom: 0px !important;
}

.detailView label, .detailViewLabel {
    /*font-size: 13px !important;*/
    color: #999 !important;
    font-weight: 100;
}

.detailView .radio label {
    padding-left: 30px;
}

p {
    font-size: 14px;
    color: #000;
}

.detailView .userDetail, .detailView .transactionList, .detailView .transactionDetail {
    height: 100%;
}

.detailView .userDetail .mainUserCard {
    background-color: #fff;
    padding: 0px;
    border: 1px solid #dddddd;
}

.detailView .listing {
    height: calc(100% - 71px);
    height: -webkit-calc(100% - 71px);
    height: -moz-calc(100% - 71px);
    overflow-y: auto;
}

.detailView .transactionList, .detailView .transactionDetail {
    padding: 0px;
}

.detailView .transactionList .traDiv {
    margin: 5px;
    overflow: hidden;
    width: calc(100% - 5px);
}

.detailView .transactionList .traDiv img {
    height: 25px;
    width: 25px;
    position: absolute;
    left: 0;
    margin-top: 15px;
    border-radius: 50%;
}

.detailView .profilePic {
    height: 80px;
    width: 80px;
}

.detailView .contactName a {
    text-decoration: underline;
}

.detailView .contactDetailMobile {
    color: #f00;
}

.detailView .address, .detailView .serviceAndBalance {
    padding: 10px 0px 1px 10px;
    line-height: 1.5;
}

.detailView .parents {
    background-color: #f8f8f8;
    padding: 10px 0px 1px 10px;
    line-height: 1.5;
}

.detailView .transactionFilter {
    padding: 5px;
    background-color: #fff;
}

.detailView .transactionFilter input, .detailView .transactionFilter select {
    /*width: 110px;*/
}

.detailView .transactionFilter button {
    height: 30px;
    margin-top: 0px;
    /*padding: 0px 10px 0px 10px;*/
}

.detailView .transactionDiv {
    min-height: 50px;
    padding: 10px;
    color: #000;
    background-color: #fff;
}

.detailView .transactionDiv:hover {
    background-color: #EEF4F7;
}

.detailView .transactionDiv:active {
    background-color: #EEF4F7;
}

.detailView .active {
    /*background-color: #f8f8f8;*/
}

.transactionDetail .title {
    background-color: #E2F0F6;
    padding: 10px 0px 10px 0px;
    font-size: 15px;
}

.width50 {
    width: 50%;
}

.background-white {
    background-color: #ffffff !important;
}

.text-success {
    color: #4CAF50;
}

.text-danger {
    color: #F44336;
}

.text-gray {
    color: #999999;
}

.c-b {
    color: #000;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.leftFloat {
    float: left;
}

.rightFloat {
    float: right;
}

.clear {
    clear: both;
}

.paddingZero {
    padding: 0px;
}

.p-0_i {
    padding: 0 !important;
}
.p-8-16_i{
    padding: 8px 16px !important;
}
.p-15-10_i{
    padding: 15px 10px !important;
}

.padding-left-zero {
    padding-left: 0px;
}

.fullHeight {
    height: 100%;
}

.fullWidth {
    width: 100%;
}

.text-transaction {
    color: #808080;
}

.divider {
    margin: 10px;
    width: calc(100% - 20px);
}

.transaction-active {
    border-bottom: 2px solid #607D8B !important;
    background-color: #EEF4F7 !important;
}

.background-gray {
    background-color: #F0F0F0;
}

.modal-body .log {
    background-color: #ffffff;
}

.border-call {
    border-bottom: 1px solid #dadada;
}

.whitebox {
    background-color: #FFFFFF;
}

.modal .row {
    margin: 0px;
}

/*.setting-panel{border: 1px solid #333333;}*/
.padding {
    padding: 15px !important;
}

.mass-problem-panel .panel-heading, .setting-panel .panel-heading {
    margin: 0px;
    padding: 15px;
}

.mass-problem-panel .row, .setting-panel .row {
    margin: 0px;
}

.mass-problem-panel .md-list-item {
    padding: 15px 10px !important;
}

.mass-problem-panel .mass-problem h3 {
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 10px;
    font-weight: 600;
}

.mass-problem-panel .mass-problem span {
    font-size: 90% !important;
    color: #888888;
}

.mass-problem-panel .mass-problem span small {
    color: #333333;
}

.mass-problem span {
    font-size: 90% !important;
    color: #888888;
}

.mass-problem span small {
    color: #333333;
}

#issueSettingModal .issue-list-item {
    padding: 12px 10px;
    border: 1px solid #F1F1F1;
}

#issueSettingModal .issue-list-item h3 {
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 10px;
}

#issueSettingModal .issue-list-item span {
    font-size: 90% !important;
    color: #888888;
}

#issueSettingModal .issue-list-item span small {
    color: #333333;
}

.collection {
    list-style-type: none
}

.collection li {
    width: 100%;
    height: 50px;
    border: 1px solid #f5f5f5;
}

.settingBox {
    padding: 1px 15px 15px 15px;
    height: 105px;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.15);
}

.settingBox:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.padd-left-right-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.padd-left-right-15 {
    padding: 0px 15px !important;
}

.padd-left-right-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.massLink .active, .settingLink .active {
    background-color: #DDDEE0;
}

.massLink, .settingLink {
    border-radius: 0px;
}

.massLink li, .settingLink li {
    border-radius: 0px;
}

.overflowHidden {
    overflow: hidden
}

.overflowAuto {
    overflow: auto
}

.borderRight {
    border-right: 1px solid #E7E7E7;
}

.height90P {
    height: calc(100% - 50px);
}

.padd-left-0 {
    padding-left: 0px;
}

.bread-crumb, .addIcon {
    font-size: 30px;
}

.selector {
    padding: 15px 0px;
}

md-radio-group {
    border: none !important;
}

.radio {
    margin: 0px !important;
}

md-radio-button .md-label, .md-switch-thumb .md-label {
    margin-left: 5px !important;
}

.modal-content {
    border-radius: 1px;
}

md-radio-button, .md-switch-thumb {
    margin: 10px;
}

.action-button {
    margin-right: 30px;
    height: 35px !important;
    margin-top: -28px;
}

.small-fab {
    width: 40px !important;
    height: 40px !important;
    line-height: 10px !important;
}

#addNewLeadModal .modal-dialog {
    height: 400px;
    width: 600px;
}

#addMassProblem .modal-dialog {
    height: 500px;
    width: 600px;
}

#addMassProblem md-radio-button {
    margin-left: 0px;
    margin-right: 40px;
}

/*
*
*        Information pop up code
*
*/

.popup-block {
    background-color: #F0F0F0;
    padding: 15px;
    border-radius: 0px;
    /*border-radius: 5px;
    background: #f9f9f9;
    border: solid 1px #ddd;
    overflow: hidden;*/
}

.pop-title {
    background: #fff;
    padding: 15px 10px;
}

.pop-title:after {
    clear: both;
    display: block;
    content: "";
}

.pop-title .left {
    float: left;
}

.pop-title .left label {
    margin: 0 5px 0 0;
}

.pop-title .right {
    float: right;
}

.pop-title .right ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.pop-title .right ul li {
    display: inline-block;
    padding-left: 10px;
    margin-left: 10px;
    border-left: solid 1px #ddd;
}

.pop-title .right ul li:first-child {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
}

.pop-title .right ul li a {
    display: block;
    display: block;
    font-size: 16px;
}

.breadcrumb-block {
    /*background: #e1e1e1;
    padding: 10px 10px;*/
    background-color: #F0F0F0;
    margin-bottom: 15px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(160, 160, 160, 0.2);
}

.breadcrumb-block .breadcrumb {
    background: none;
    margin: 0;
    padding: 0;
}

.breadcrumb-block .breadcrumb > .active {
    color: #607D8B;
}

.breadcrumb-block .error-icn {
    margin-left: 10px;
}

.info-block:after {
    clear: both;
    display: block;
    content: "";
}

.three-block {
    float: left;
    width: 33.33333%;
    height: 100%; /*overflow:auto;*/
}

/*.three-block.border{border-right:solid 1px #ddd;}*/
.three-block .item-listing {
    height: calc(100% - 100px);
    overflow: auto;
}

.three-block .item-listing .listing-block {
    padding: 0px 10px;
}

.three-block .item-listing .listing-block .listing, .three-block .item-listing .listing-block .case {
    margin: 10px 0px;
}

.three-block .item-listing .listing-block .listing .md-list-item-content h4 {
    margin-bottom: 10px;
}

.paddRightZero {
    padding-right: 0px;
}

.listing-block .listing .actionIcon {
    font-size: 16px;
    margin: 0;
    position: absolute;
    right: 0;
    text-align: center;
    padding: 0 10px;
    top: 0;
}

.listing-block .listing {
    padding: 10px;
    border-bottom: solid 1px #ddd;
    min-height: 70px;
    position: relative;
    background-color: #ffffff;
}

.item-listing .md-list .md-list-item.active {
    background-color: #EEF4F7;
    border-bottom: solid 2px #607D8B;
}

.listing-block .listing h3 {
    font-size: 20px;
    margin: 0 50px 0 0;
    font-weight: normal !important;
}

.listing-block .listing h4 {
    font-size: 16px;
    margin: 0 40px 0 0;
    font-weight: normal !important;
}

.listing-block .listing .percentage {
    font-size: 18px;
    margin: 0;
    position: absolute;
    right: 0;
    color: #607D8B;
    text-align: center;
    padding: 0 10px;
    font-weight: 300;
    top: 50%;
    margin-top: -16px;
}

.case-block .case {
    padding: 10px;
    border-bottom: solid 1px #ddd;
    min-height: 70px;
    background-color: #ffffff
}

.case-block .case.active {
    background-color: #EEF4F7;;
    border-bottom: solid 2px #607D8B;
}

.case-block .case h3 {
    font-size: 18px;
    margin: 0 30px 5px 0;
    font-weight: normal !important;
}

.case-block .case p {
    font-size: 14px;
    margin: 0;
    line-height: 20px;
}

.case-info-block .case-info {
    padding: 10px;
    background: #fff;
}

.case-info-block .case-info .case-info-name {
    font-size: 16px;
    margin: 0 0 10px;
}

.case-info-block .case-info p {
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 15px;
}

.case-info-block .case-info .label {
    margin: 0 10px 0 0;
}

.log-wrap {
    /*border-top: solid 1px #ddd;
    padding: 10px 10px 0px;*/
    padding: 10px;
    background-color: #F0F0F0;
    margin-top: 15px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(160, 160, 160, 0.2);
    height: 47px;
}

.log-wrap .log-block {
    display: inline-block;
}

.log-wrap .breadcrumb {
    background: #f1f1f1;
    margin: 0px;
    padding: 3px 7px;
    display: inline-block;

}

.log-wrap .breadcrumb > .active {
    color: #607D8B;
}

.log-wrap .btn-success {
    float: right;
    padding: 3px 15px;
}

/*
*
*        IEnd information popup
*
*/

.queryListContainer {
    padding: 0px;
    height: 100%;
}

.queryListContainer .query-listing, .queryListContainer .lead-listing {
    height: calc(100% - 80px);
    overflow: auto;
}

.queryListContainer .query-listing h3 {
    font-size: 20px;
    margin: 0 50px 0 0;
    font-weight: normal !important;
}

.queryListContainer .query-listing h4 {
    font-size: 17px;
    margin: 0 40px 0 0;
    font-weight: normal !important;
}

.queryListContainer .lead-listing h3 {
    font-size: 18px;
    margin: 0 30px 5px 0;
    font-weight: normal !important;
}

.queryListContainer .lead-listing h4 {
    font-size: 16px;
    margin: 0 25px 5px 0;
    font-weight: normal !important;
}

.queryList {
    padding: 10px;
    min-height: 70px;
    background-color: #ffffff;
    margin: 10px;
}

.queryListContainer .active {
    border-bottom: 2px solid #607D8B;
    background-color: #EEF4F7;
}

.selectedQueryList {
    border-right: 2px solid #dadada;
}

.editable {
    cursor: pointer;
}

.listing {
    border: 1px solid #CACACA;
}

.listing .item {
    padding: 20px;
    border-bottom: 1px solid #CACACA;
    line-height: 0.5;
}

.modal-medium {
    width: 60% !important;
    margin: 0 auto;
}

.modal-small {
    width: 45% !important;
    margin: 0 auto;
}

.modal-xs {
    width: 35% !important;
    margin: 0 auto;
}

.queryInfoSelect {
    background-color: transparent;
    box-shadow: none;
    color: #607D8B;
    font-size: 16px;
}

.modal-fix-height {
    height: 800px;
!important;
}

.fix-list-height {
    margin-top: 20px;
    height: 650px;
    overflow: auto
}

.info {
    background-color: #ffffff;
    padding: 10px
}

.info label {
    font-size: 15px;
    color: #000;
}

/*
*
*
Custom Date picket
*
*
*/

ul[datepicker-popup-wrap] {
    z-index: 100000;
}

ul[datepicker-popup-wrap] > li > div > table > tbody > tr > td > .btn, ul[datepicker-popup-wrap] > li > div > table > thead > tr > th > .btn {
    padding: 2px 3px;
    margin: 2px 3px;
    border: none;
    box-shadow: none;
}

ul[datepicker-popup-wrap] > li > div {
    padding: 5px;
}

#inline-date-picker .btn-default {
    /*color: rgba(0, 0, 0, 0.87) !important;*/
    background-color: #ffffff;
    border-color: #dddee0;
}

#inline-date-picker table {
    border-color: #FFF;
}

#inline-date-picker .btn-sm, .btn-group-sm > .btn {
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5;
    /* border-radius: 3px; */
}

#inline-date-picker .btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

#inline-date-picker .btn {
    display: inline-block;
    padding: 6px 6px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    /* white-space: nowrap; */
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    /* border-radius: 4px; */

}

.customDateTimePickerDiv {
    margin-bottom: 0px;
    width: 184px;
}

.customDateTimePickerDiv.arrow_box_up:before {
    top: -16px;
    margin-top: -9px;
    left: 25px;
}

.customDateTimePickerDiv.wide {
    min-width: 385px;
    max-width: 400px;
    max-height: 310px;
}

.customDateTimePickerDiv.arrow_box_up:after {
    top: -17px;
    margin-top: -7px;
    left: 25px;
}

.dateTime-content {
    margin: 10px 0px !important;
    /*max-height: 223px;*/
}

.time-content .verticalAlign {
    margin-left: 15px;
    margin-top: 71px;
}

.dateTime-content .btn {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

/*
*
*
custom datepicker end
*
*
*/

.arrow_box_up:after, .arrow_box_up:before, .arrow_box_left:after, .arrow_box_left:before, .arrow_box_right:after, .arrow_box_right:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box_up:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 12px;
}

.arrow_box_up:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #ccc;
    border-width: 12px;
}

.arrow_box_left:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #fff;
    border-width: 12px;
}

.arrow_box_left:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ccc;
    border-width: 13px
}

.arrow_box_right:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #fff;
    border-width: 12px;
}

.arrow_box_right:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #ccc;
    border-width: 13px
}

.massProblemList.arrow_box_up:before {
    top: -16px;
    margin-top: -9px;
    left: 55px;
}

.massProblemList.arrow_box_up:after {
    top: -17px;
    margin-top: -7px;
    left: 55px;
}

.singleMassProblemContainers {
    padding: 15px;
    margin: 15px !important;
    border: 1px solid rgb(218, 218, 218);
    width: calc(100% - 30px);
    background-color: #fff !important;
}

.singleMassProblemContainers .border-right {
    border-right: 1px solid #dadada;
}

.mass-problem-panel {
    /*text-align: center;*/
}

.mass-problem-panel > div > span {
    margin: 2px 0px;
    /*text-align: center;*/
}

.mass-problem-panel .divider {
    width: 100%;
    margin: 5px 0px;

    /*text-align: center;*/
}

.singleMassProblemThirdContainer {
    padding: 15px;
    border: 1px solid #DADADA;
    background-color: #FFF !important;
    width: 33.333% !important;
    margin-right: 0px;
    height: calc(100% - 15px);
    height: -moz-calc(100% - 15px);
    height: -webkit-calc(100% - 15px);
}

.singleMassProblemThirdContainer.marginRight {
    margin-right: 15px !important;
    width: calc(33.333% - 15px) !important;
    width: -moz-calc(33.333% - 15px) !important;
    width: -webkit-calc(33.333% - 15px) !important;
}

.callIssueThirdContainer {
    padding: 0px;
    background-color: #FFF !important;
    margin-right: 0px;
    height: 100%;
    width: calc(33.333% - 10px) !important;
    width: -moz-calc(33.333% - 10px) !important;
    width: -webkit-calc(33.333% - 10px) !important;
}

.callIssueThirdContainer .sub-title {
    padding: 10px;
    margin-bottom: 0px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(160, 160, 160, 0.2);
}

.callIssueThirdContainer .sub-content {
    height: calc(100% - 50px)
}

.callIssueThirdContainer .sub-content-with-add {
    height: calc(100% - 240px);
}

.sub-content .md-list-item-detail {
    min-height: 40px;
    overflow: auto;
    display: block;
    max-height: 60px;
}

.callIssueThirdContainer.marginRight {
    margin-right: 15px !important;

}

.callIssueThirdContainer .md-list-item.active {
    border-right: 4px solid #e91e63;
}

.singleMassProblemThirdContainer .divider {
    margin: 10px 0px;
    width: 100%;
}

.dropDownBtn {
    background-color: transparent;
    box-shadow: none;
    padding-left: 0px;
}

.dropDownBtn .caret {
    margin-left: 5px;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    /*background-color: #eaeaea;*/
    /*border-left: 1px solid #ccc;*/
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.text-bold {
    font-weight: bold;
}

.treeViewOfUL {
    max-height: 300px;
    overflow: auto;
    min-width: 200px;
}

.treeViewOfUL li {
    line-height: 23px;
}

.detailViewSelect {
    margin-left: 20px;
}

.massProblemList {
    position: absolute;
    width: 450px;
    display: none;
    z-index: 10000
}

.massProblemList .md-list.md-whiteframe-z0.bg-white {
    max-height: 400px;
    overflow: auto;
}

.LeadSetting {
    height: calc(100% - 56px);
}

.tab-title {
    color: #000 !important;
    font-size: 16px;
    font-weight: bold !important;
}

.md-header-items-container {
    border-bottom: 1px solid #dddee0;
}

.m-b-0 {
    margin-bottom: 0px;
}

.m-t--5 {
    margin-top: -5px;
}
.m-t-0 {
    margin-top: 0;
}

.m-t-0_i {
    margin-top: 0!important;
}

.m-t-2 {
    margin-top: 2px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-t-22 {
    margin-top: 22px;
}
.m-t-23 {
    margin-top: 23px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-15 {
    margin-bottom: 15px;
}

.f-b {
    font-weight: bold;
}

.o-a {
    overflow: auto;
}

.t-21_i {
    top: 21px !important;
}

.m-0 {
    margin: 0;
}
.m-0i {
    margin: 0!important;
}

.m-l-0 {
    margin-left: 0;
}
.m-l-5 {
    margin-left: 5px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-l-2 {
    margin-left: 2px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-r-5 {
    margin-right: 5px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-20 {
    margin-right: 20px;
}
.p-t-1 {
    padding-top: 1px;
}
.p-t-5 {
    padding-top: 5px;
}
.p-t-7 {
    padding-top: 7px;
}

.p-t-10 {
    padding-top: 10px;
}
.p-t-10_i {
    padding-top: 10px!important;
}

.p-t-12 {
    padding-top: 12px;
}

.p-t-12_i {
    padding-top: 12px!important;
}

.p-t-15 {
    padding-top: 15px;
}

.p-t-20 {
    padding-top: 20px;
}

.p-t-25 {
    padding-top: 25px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-b-0 {
    padding-bottom: 0;
}

.p-b-10 {
    padding-bottom: 10px;
}

.p-b-10_i {
    padding-bottom: 10px!important;
}

.p-b-15 {
    padding-bottom: 15px;
}

.p-r-0 {
    padding-right: 0;
}
.p-r-10 {
    padding-right: 10px;
}
.p-r-20 {
    padding-right: 20px;
}

.p-l-0 {
    padding-left: 0 !important;
}

.p-l-5 {
    padding-left: 5px;
}
.p-l-15 {
    padding-left: 15px;
}
.p-l-75 {
    padding-left: 75px;
}

.p-l-20 {
    padding-left: 20px;
}

.f-w-n {
    font-weight: normal;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-l-8 {
    margin-left: 8px;
}

.m-t-8 {
    margin-top: 8px;
}

.tineMceEditor {
    height: calc(100% - 110px);
    -webkit-height: calc(100% - 110px);
    -moz-height: calc(100% - 110px);
}

.customSearch {
    /*  background: url(../images/search_ico.png) no-repeat 8px 9px;
      padding-left: 30px;*/
    position: absolute;
    top: 6px;
    left: 0px;
    font-size: 20px;
    color: #627F8C;

}

.p-r {
    position: relative;
}

.md-list-item-edit {
    background-color: #F0F0F0 !important;
    padding: 15px 0px;
    border-right: 0px !important;
    border-radius: 3px;
}

.md-list-item-content-edit {
    padding: 15px;
    background: none repeat scroll 0% 0% rgb(255, 255, 255);
    border-bottom: 1px solid rgb(218, 218, 218);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border-top: 0px none;
}

.theaterAddDivider {
    width: calc(100% + 30px);
    margin: 15px 0px;
    margin-left: -15px;
    border-top: 1px solid #F7F7F7;
}

/*************
*
*
Change Password Start
*
*
*************/
.changePasswordPopover {
    left: -264px;
    top: -90px;
    width: 250px;
    padding: 15px 15px 10px 15px !important;
}

.changePasswordPopover.arrow_box_right:before {
    top: calc(40% - 1px);
    right: -26px;
}

.changePasswordPopover.arrow_box_right:after {
    top: 40%;
    right: -24px;
}

/*************
*
*
Change Password End
*
*
*************/

/**
profile start
**/
.profileLi {
    background-image: url(../images/bg.png);
    background-size: cover;
    display: inline-block;
    color: #333;
    height: 100%;
}

.profileDiv {
    padding: 14px 20px 10px 20px;
    width: 250px;
    height: 100%;
}

.profileImage {
    float: left;
    display: inline-block;
    margin-top: -5px;
}

.currentUserProfile.arrow_box_up:before {
    top: -16px;
    margin-top: -9px;
    left: 30px;
    /*border-bottom-color: #b0bec5*/
}

.currentUserProfile.arrow_box_up:after {
    top: -17px;
    margin-top: -7px;
    left: 30px;
    /*border-bottom-color: #E4E4E4;*/
}

/**
profile end
**/
.f-w-600 {
    font-weight: 600 !important;
}

/**
filter in call receive
start
**/
.transactionFilterAni {
    position: absolute;
    top: -6px;
    left: -6px;
    background: #fff;
    width: calc(100% + 12px);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    height: 450px;
    padding: 15px;
    z-index: 99;
}

.transactionFilterAni.ng-enter,
.transactionFilterAni.ng-leave {
    /*transition: .5s linear all;*/
    -webkit-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -moz-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -ms-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -o-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
}

.transactionFilterAni.ng-enter.ng-enter-active,
.transactionFilterAni.ng-leave {
    /*height: 289px;*/
    opacity: 1;
}

.transactionFilterAni.ng-leave.ng-leave-active,
.transactionFilterAni.ng-enter {
    /*height: 0;*/
    opacity: 0;
}

/*

.transactionFilterAniLab.ng-enter {
    -webkit-transition: 1s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -moz-transition: 1s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -ms-transition: 1s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -o-transition: 1s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    transition: 1s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
}

.transactionFilterAniLab.ng-enter.ng-enter-active,
.transactionFilterAniLab.ng-leave {
    opacity: 1;
}

.transactionFilterAniLab.ng-leave.ng-leave-active,
.transactionFilterAniLab.ng-enter {
    opacity: 0;
}

.listTransactionHeight {
    height: calc(100% - 330px) !important;
    height: -webkit-calc(100% - 330px) !important;
    height: -moz-calc(100% - 330px) !important;
}
*/

/*

.transactionFilterList {
    transition: .1s linear all;
}

.transactionFilterList.ng-leave.ng-leave-active {
    transition: 0.4s linear all;
}
*/

/**
filter in call receive
end
**/
.p-r {
    position: relative;
}

/**
filter in Customer
start
**/
.customerFilterAni {
    position: absolute;
    right: -6px;
    top: -25px;
    background: #fff;
    width: 450px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    height: 500px;
    padding: 15px;
    z-index: 99;
}

.customerFilterAni.ng-enter,
.customerFilterAni.ng-leave {
    /*transition: .5s linear all;*/
    -webkit-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -moz-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -ms-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -o-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
}

.customerFilterAni.ng-enter.ng-enter-active,
.customerFilterAni.ng-leave {
    /*height: 289px;*/
    opacity: 1;
}

.customerFilterAni.ng-leave.ng-leave-active,
.customerFilterAni.ng-enter {
    /*height: 0;*/
    opacity: 0;
}

/*

/**
filter in Transaction
start
**/
.transactionsFilter {
    position: absolute;
    width: 100%;

    /* position: absolute;
     right: -6px;
     top: -25px;
     background: #fff;
     width: 450px;
     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
     height: 305px;
     padding: 15px;
     z-index: 99;*/
}

.transactionsFilter.ng-enter,
.transactionsFilter.ng-leave {
    /*transition: .5s linear all;*/
    -webkit-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -moz-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -ms-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -o-transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    transition: 0.5s cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
}

.transactionsFilter.ng-enter.ng-enter-active,
.transactionsFilter.ng-leave {
    /*height: 289px;*/
    opacity: 1;
}

.transactionsFilter.ng-leave.ng-leave-active,
.transactionsFilter.ng-enter {
    /*height: 0;*/
    opacity: 0;
}

.border-right {
    border-right: 1px solid #eaebec !important;
}
.border-left {
    border-left: 1px solid #eaebec !important;
}
.border-bottom {
    border-bottom: 1px solid #eaebec !important;
}
.border-top {
    border-top: 1px solid #eaebec;
}

.transaction-filter-content {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    padding: 10px 15px;
}

.transactionsListWithFil {
    margin-top: 210px;
    height: calc(100% - 210px);
}

.orderListWithFil {
    height: calc(100% - 190px);
}

.customListWithFil {
    margin-top: 135px;
    height: calc(100% - 135px);
}

.filterBlock {
    height: 70px;
}

.transactionStatusUl {
    right: 0px;
    left: -90px;
}

.t-t-c {
    text-transform: capitalize;
}

.transactionListWithPage {
    height: calc(100% - 45px);
}

.seat {
    width: 20px;
    padding: 2px;
    margin-top: 2px;
    font-size: 11px;
    background-color: #F1F1F1;
}

.red {
    /*background-color: red;*/
    background-color: #929DA3 !important;
    color: #fff !important;
}

.d-b {
    display: block;
}

.d-b-i {
    display: block !important;
}

.seatRowHeight {
    height: 25px;
}

.seatInput {
    font-size: 12px;
    padding: 5px;
}

.classTitle {
    height: 30px;
    padding: 5px 0px;
    /* text-align: center; */
    border: 1px solid #eaebec;
    /* font-weight: bold; */
    border-radius: 8px;
    padding: 5px 0px;
    background: #FCFCFC;
    font-size: 13px
}

.seatContainer {
    white-space: nowrap;
    overflow: auto;
    width: calc(100% - 30px);
    margin: 0px 15px;
}

.classContainer {
    margin: 5px 0px 25px 0px;
    width: 100%;
    padding: 0px;
}

.rowContainer {
    width: 100%;
    margin-top: 5px !important;
}

.rowList {
    list-style-type: none;
    vertical-align: middle;
    margin-top: 2px;
}

.previewRowBtn {
    margin-right: 15px;
    font-weight: bold;
    width: 26px;
}

.classTitleDiv {
    width: calc(100% - 30px);
    text-align: center;
    display: inline-block;
}

.v-h {
    visibility: hidden;
}

.seatAllocation {
    background-color: #fff !important;
    color: #333 !important;
}

.d-i-b {
    display: inline-block;
}

.seatLayoutDiv {
    /*width: calc(100% - 44px)*/
}

.seatLayoutOptions {
    width: 255px;
}

.seatStatistic {
    width: 18px;
    height: 18px;
    border: 1px solid #dadada;
    display: inline-block;
}

.screenLayout-div {
    height: 50px;
    vertical-align: middle;
    text-align: center;
    padding: 15px;
    margin: 0 15px;
    border: 1px solid #dadada;
    border-bottom-top-radius: 10px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 80px;
    border-top-left-radius: 80px;
    border-bottom-right-radius: 0px;
    background: linear-gradient(#fff, #eaebec);
    font-weight: bold;
    text-transform: uppercase;
}

.f-13 {
    font-size: 13px !important;
}
.f-14 {
    font-size: 14px !important;
}
.f-12 {
    font-size: 12px !important;
}
.f-18 {
    font-size: 18px !important;
}
.f-20 {
    font-size: 20px !important;
}

.note {
    /*background-image: url(../images/8zEoh.png);*/
    background-size: cover;
    display: inline-block;
    color: #333;
    height: 100%;
}

.p-t-b-5 {
    padding: 5px 0px;
}

.p-l-r-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.p-l-r-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.screenClassRs {
    position: absolute;
    top: 10px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-a {
    position: absolute;
}

.t-10 {
    top: 10px;
}

.rollOverCont.arrow_box_left:after {
    top: calc(40% - 1px);
    left: -24px;
}

.rollOverCont.arrow_box_left:before {
    top: calc(40% - 2px);
    left: -28px;
    border-right-color: #607D8B;
}

.m-t-b-5 {
    margin-bottom: 5px;
    margin-top: 5px;
}

.rollover-label {
    font-size: 15px !important;
    text-transform: uppercase;
}

.m-b-3 {
    margin-bottom: 3px;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.f-s-13 {
    font-size: 13px;
}

.f-s-12 {
    font-size: 12px;
}

.m-t-6 {
    margin-top: 6px;
}

.f-s-15 {
    font-size: 15px;
}

.f-s-17 {
    font-size: 17px;
}

.f-s-16 {
    font-size: 16px;
}

.showAction.arrow_box_right:before {
    top: calc(40% + 102px);
    right: -26px;
}

.showAction.arrow_box_right:after {
    top: 79%;
    right: -22px;
}
.showRates.arrow_box_right:before {
    top: 23px;
    right: -26px;
}

.showRates.arrow_box_right:after {
    top: 24px;
    right: -24px;
}

.t-caret {
    position: absolute;
    top: 15px;
    right: 2px;
}

.show-f-l {
    font-size: 11px !important;
    margin-bottom: 0px;
}

.bookingRP {
    /*margin-top: 185px !important;*/
    /*height: calc(100% - 185px) !important;*/
}

.bookingFull {
    /*margin-top: 33px !important;*/
    /*height: calc(100% - 33px) !important;*/
    background-color: white;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

a.disabled-li,
a.disabled-li:visited,
a.disabled-li:active,
a.disabled-li:hover {
    color: #aaa !important;
    cursor: not-allowed;
    background: #FFF !important;
}

.v-a-m {
    vertical-align: middle !important;

}

.screenRowBtn {
    background-color: #878787;
    color: #fff !important;
}

.messageMapContainer {
    min-height: 550px;
    height: 100%;
    border: 1px solid #eaebec;
    display: inline-block;
    float: left;
    width: 320px;
    /*padding: 15px;*/
}

.msgMapMSGContainer {
    min-height: 550px;
    height: 100%;
    /* border: 1px solid #eaebec; */
    border-left: 0px;
    overflow: auto;
    margin-left: 12px;
    overflow-x: hidden;
}

.msgMapMSGDIV {
    overflow-x: visible;
    overflow-y: hidden;
    white-space: nowrap;
    display: block;
    margin-left: 0px;
    margin-right: 7px;
    height: 100%;
    margin-left: 20px;
    border: 1px solid #eaebec;
}

.msgMapAction {
    /*position: absolute;
    bottom: 15px;
    left: 0px;
    width: 100%;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;*/
}

.b-0 {
    border: 0px;
}

.d-n {
    display: none;
}

.t-t-l {
    text-transform: lowercase;
}

.t-t-c {
    text-transform: capitalize;
}

.orderPayments.ng-enter {
    -webkit-transition: 0.5s linear all;
    -moz-transition: 0.5s linear all;
    -ms-transition: 0.5s linear all;
    -o-transition: 0.5s linear all;
    transition: 0.5s linear all;
}

.orderPayments.ng-enter {
    max-height: 0;
    opacity: 1;
}

.orderPayments.ng-enter.ng-enter-active {
    max-height: 999px;
    opacity: 1;
}

.orderPayments.ng-leave {
    -webkit-transition: 0.1s linear all;
    -moz-transition: 0.1s linear all;
    -ms-transition: 0.1s linear all;
    -o-transition: 0.1s linear all;
    transition: 0.1s linear all;
}

/*
.orderPayments.ng-enter.ng-enter-active,
.orderPayments.ng-leave {
    min-height: 100px;
    */
/*opacity: 1;*//*

}
*/
.orderPayments.ng-leave {
    max-height: 999px;
    opacity: 1;
}

.orderPayments.ng-leave.ng-leave-active {
    max-height: 0;
    opacity: 1;
}

.orderDetailPaymentsTitle {
    color: #333 !important;
    font-weight: bold;
    font-size: 15px !important;
    border-bottom: 1px solid #eaebec;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.f-s-14 {
    font-size: 14px;
}

.m-r-3 {
    margin-right: 3px;
}
.treeViewOfUL .fixedSearchBar {
    position: fixed;
    background-color: #fff;
    z-index: 1000;
}
.h-50{height: 50px;}
.editBtn{padding: 3px 10px;font-size: 15px;color: #666;float: right;}
.o-x-h{overflow-x: hidden;}
.movieDiv{height: 215px;}
.movieDiv .movieImg{height: 175px;width: 100%;}
.movieDiv .removeImgIcon{position: absolute;color: #000;right: 10px;font-size: 18px;cursor: pointer;}

.v-align {
    vertical-align: middle;
    height: 100%;
    display: table;

}

.v-align > div {
    display: table-cell;
    vertical-align: middle
}



.mvNameExist md-progress-linear[md-mode=indeterminate] {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 3px;
    transition: none; }
.mvNameExist md-progress-linear[md-mode=indeterminate] .md-container {
    transition: none;
    top: auto;
    height: 3px; }
.mvNameExist md-progress-linear[md-mode=indeterminate].ng-enter {
    transition: opacity 0.15s linear; }
.mvNameExist md-progress-linear[md-mode=indeterminate].ng-enter.ng-enter-active {
    opacity: 1; }
.mvNameExist md-progress-linear[md-mode=indeterminate].ng-leave {
    transition: opacity 0.15s linear; }
.mvNameExist md-progress-linear[md-mode=indeterminate].ng-leave.ng-leave-active {
    opacity: 0; }



md-toast{
    z-index: 5000000;
}

.d-i {
    display:inline;
}
.p-o-imp{padding: 0!important;}
.w-60{width: 60px;}
.w-65{width: 65px;}
.h-20{height: 20px;}

.header-label{
    font-size:14px;
    margin-left: 10px;
}
.header-content{
    font-size:14px;
    font-weight: bold;
    margin-right: 10px;
}

.contactCard .name{
    font-size: 16px;
}

.contactCard .number{
    font-size: 18px;
}
.detailTableOfRecord{border: #CFCFCF solid 1px;}
.detailTableOfRecord tr th, .detailTableOfRecord tr td{padding: 5px;}

#callReceivePopupInitial .md-list .active {
    border: 0px;
    border-left: 3px solid #ff0000;
}

.lightGreyBackground{
    background: #e7e8ea;
}
.daterangepicker.dropdown-menu.single.opensright.show-calendar .row{margin: 0px;}
.daterangepicker.dropdown-menu.single.opensright.show-calendar .row .ranges {width: 100%;padding-bottom: 17px;}
.daterangepicker.dropdown-menu.show-calendar.openscenter .row .ranges {margin-right: 8px;padding-bottom: 4px;}
.h-100per-75p{height: calc(100% - 75px);}
.h-100per-150p{height: calc(100% - 150px);}
.h-100per-230p{height: calc(100% - 230px);}
.h-100per-300p{height: calc(100% - 300px);}
.h-100per-321p{height: calc(100% - 321px);}
.w-110p{width: 110px;}
.h-30{height: 30px;}
.h-50p{height: 50px;}
.h-130p{height: 130px;}
.h-140p{height: 140px;}
.h-160p{height: 160px;}
.h-185p{height: 185px;}
.h-200{height: 200px;}
.h-230p{height: 230px;}
.h-280p{height: 280px;}
.h-300p{height: 300px;}
.nowrap{ white-space: nowrap; }
.h-100per-220p{height: calc(100% - 220px);}
.min-h-100per-135p{min-height: calc(100% - 135px);}
.min-h-100per-165p{min-height: calc(100% - 165px);}
.min-h-100per-200p{min-height: calc(100% - 200px);}

.easyRecharge-panel .record-list table thead tr th{padding: 5px 10px!important;}
.easyRecharge-panel .record-list table tbody tr td{padding: 5px 10px!important;}
.easyRecharge-panel .record-list table tfoot tr td{padding: 5px 10px!important;}

.postpaid-panel .record-list table thead tr th{padding: 5px 10px!important;}
.postpaid-panel .record-list table tbody tr td{padding: 5px 10px!important;}
.postpaid-panel .record-list table tfoot tr td{padding: 5px 10px!important;}

.dth-panel .record-list table thead tr th{padding: 5px 10px!important;}
.dth-panel .record-list table tbody tr td{padding: 5px 10px!important;}
.dth-panel .record-list table tfoot tr td{padding: 5px 10px!important;}

.link-color{color: #337ab7;}



#pageLoaderContainer .progress-circle {
    vertical-align: middle;
    text-align: center;
    height: 100%;
    width: 100%;
    display: table;
    font-size: 16px;
    color: #989898;
}

#pageLoaderContainer .progress-circle .progress-circle-outer {
    display: table-cell;
    vertical-align: middle;
}
#pageLoaderContainer .progress-circle .progress-circle-outer .progress-circle-inner {
    display: table;
    margin: 0 auto;
}


.fts-orange{
    background: #EB883F;
}

.profile-icon {
    background: #EB883F;
    color: #FFF;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 18px;
}


md-switch.md-checked .md-bar {
    background-color: rgba(235,136,63,0.5);
}

md-switch.md-checked .md-thumb {
    background-color: rgb(235,136,63);
}
.text-white{color: #ffffff;}

.text-gray-hover:hover {color: #999999;}
.text-primary-hover:hover{color:#000;}
.text-success-hover:hover{color:#4caf50;}
.text-danger-hover:hover{color:#f44336;}
.text-warning-hover:hover{color:#ffc107;}
.text-info-hover:hover{color:#03a9f4;}

.text-muted-default{opacity: 0.75;}
.h-a_i{height: auto!important;}
.wordBreak{word-wrap: break-word;}

.table-cell-padd-7 tr th, .table-cell-padd-7 tr td{padding: 7px!important;}

.table-cell-padd-7-10 tr th, .table-cell-padd-7-10 tr td{padding: 7px 10px!important;}