/* Generated by Font Squirrel (http://www.fontsquirrel.com) on March 18, 2015 */

@font-face {
    font-family: 'Lato-Regular';
    src: url('Lato-Regular.eot');
    src: url('Lato-Regular.eot?#iefix') format('embedded-opentype'),
         url('Lato-Regular.woff2') format('woff2'),
         url('Lato-Regular.woff') format('woff'),
         url('Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;}